import { camelCase } from 'lodash';
import purchaseOrdersApi from '@/apis/purchaseOrders/purchaseOrders';
import gatewaySupplyApi from '@/apis/gatewaySupply';
import { purchaseOrdersTypes } from './purchaseOrdersTypes';

export default {
  state: {
    statuses: {
      DRAFTED: 'DRAFTED',
      SUGGESTED: 'SUGGESTED',
      AWAITING_VALIDATION: 'AWAITING_VALIDATION',
      VALIDATED: 'VALIDATED',
      AWAITING_DELIVERY: 'AWAITING_DELIVERY',
      DELIVERED: 'DELIVERED',
    },
    currentPurchaseOrder: {},
    awaitingValidationPurchaseQuantities: {},
    purchaseOrders: {
      total: 0,
      items: [],
    },
    draftedOrders: {
      total: 0,
      items: [],
    },
    suggestedOrders: {
      total: 0,
      items: [],
    },
    awaitingValidationOrders: {
      total: 0,
      items: [],
    },
    numberOfOrdersToValidate: 0,
    validatedOrders: {
      total: 0,
      items: [],
    },
    awaitingDeliveryOrders: {
      total: 0,
      items: [],
    },
    deliveredOrders: {
      total: 0,
      items: [],
    },
    purchaseHistory: {
      total: 0,
      items: [],
    },
    delivers: [],
    fetchAllPurchaseOrders: {
      pending: false,
    },
    fetchAllDraftedOrders: {
      pending: false,
    },
    fetchAllSuggestedOrders: {
      pending: false,
    },
    fetchAllAwaitingValidationOrders: {
      pending: false,
    },
    fetchAllValidatedOrders: {
      pending: false,
    },
    fetchAllAwaitingDeliveryOrders: {
      pending: false,
    },
    fetchAllDeliveredOrders: {
      pending: false,
    },
    fetchAllDelivers: {
      pending: false,
      locationUuid: null,
    },
    fetchOne: {
      pending: false,
    },
    fetchOneTasks: {
      pending: false,
    },
    getHistory: {
      pending: false,
    },
    searchListPurchaseFilters: {
      page: 1,
      perPage: 20,
      selectedDeliver: null,
      selectedStatus: null,
      selectedOrderingDate: null,
      selectedDeliveryDate: null,
      sortField: 'delivery_date',
      sortOrder: 'desc',
      searchDeliver: '',
    },
  },
  mutations: {
    [purchaseOrdersTypes.FETCH_ALL_PURCHASES](state, purchaseOrders) {
      state.purchaseOrders.total = purchaseOrders.total;
      state.purchaseOrders.items = purchaseOrders.items;
    },
    [purchaseOrdersTypes.FETCH_ALL_PURCHASES_PENDING]: (state, pending) =>
      (state.fetchAllPurchaseOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_ALL_DELIVERS]: (state, delivers) => {
      state.delivers = delivers;
    },
    [purchaseOrdersTypes.FETCH_ALL_DELIVERS_PENDING]: (state, pending) => (state.fetchAllDelivers.pending = pending),
    [purchaseOrdersTypes.FETCH_ONE_PURCHASE]: (state, { purchase, status }) => {
      state.currentPurchaseOrder = purchase;
      state.currentPurchaseOrder['status'] = status;
    },
    [purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING]: (state, pending) => (state.fetchOne.pending = pending),
    [purchaseOrdersTypes.FETCH_ONE_PURCHASE_TASKS]: (state, tasks) => {
      if (!state.currentPurchaseOrder) {
        return;
      }

      state.currentPurchaseOrder.tasks = tasks;
    },
    [purchaseOrdersTypes.FETCH_ONE_PURCHASE_TASKS_PENDING]: (state, pending) => (state.fetchOneTasks.pending = pending),
    [purchaseOrdersTypes.UNSET_CURRENT_PURCHASE]: (state) => (state.currentPurchaseOrder = {}),
    [purchaseOrdersTypes.FETCH_ALL_DRAFTED_ORDERS]: (state, drafted) => {
      state.draftedOrders.items = drafted.items;
      state.draftedOrders.total = drafted.total;
    },
    [purchaseOrdersTypes.FETCH_ALL_DRAFTED_ORDERS_PENDING]: (state, pending) =>
      (state.fetchAllDraftedOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_ALL_SUGGESTED_ORDERS]: (state, suggested) => {
      state.suggestedOrders.items = suggested.items;
      state.suggestedOrders.total = suggested.total;
    },
    [purchaseOrdersTypes.FETCH_ALL_SUGGESTED_ORDERS_PENDING]: (state, pending) =>
      (state.fetchAllSuggestedOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_ALL_AWAITING_VALIDATION_ORDERS]: (state, awaitingValidation) => {
      state.awaitingValidationOrders.items = awaitingValidation.items;
      state.awaitingValidationOrders.total = awaitingValidation.total;
    },
    [purchaseOrdersTypes.FETCH_ALL_AWAITING_VALIDATION_ORDERS_PENDING]: (state, pending) =>
      (state.fetchAllAwaitingValidationOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_ALL_VALIDATED_ORDERS]: (state, validated) => {
      state.validatedOrders.items = validated.items;
      state.validatedOrders.total = validated.total;
    },
    [purchaseOrdersTypes.FETCH_ALL_VALIDATED_ORDERS_PENDING]: (state, pending) =>
      (state.fetchAllValidatedOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_ALL_AWAITING_DELIVERY_ORDERS]: (state, awaitingDelivery) => {
      state.awaitingDeliveryOrders.items = awaitingDelivery.items;
      state.awaitingDeliveryOrders.total = awaitingDelivery.total;
    },
    [purchaseOrdersTypes.FETCH_ALL_AWAITING_DELIVERY_ORDERS_PENDING]: (state, pending) =>
      (state.fetchAllAwaitingDeliveryOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_ALL_DELIVERED_ORDERS]: (state, delivered) => {
      state.deliveredOrders.items = delivered.items;
      state.deliveredOrders.total = delivered.total;
    },
    [purchaseOrdersTypes.FETCH_ALL_DELIVERED_ORDERS_PENDING]: (state, pending) =>
      (state.fetchAllDeliveredOrders.pending = pending),
    [purchaseOrdersTypes.FETCH_MORE]: (state, { status, purchaseOrders }) => {
      state[`${camelCase(status)}Orders`].items.push(...purchaseOrders.items);
      state[`${camelCase(status)}Orders`].total = purchaseOrders.total;
    },
    [purchaseOrdersTypes.FETCH_NUMBER_OF_ORDERS_TO_VALIDATE]: (state, total) =>
      (state.numberOfOrdersToValidate = total),
    [purchaseOrdersTypes.RESET_ORDERS_LIST]: (state, status) => {
      state[`${camelCase(status)}Orders`].items = [];
      state[`${camelCase(status)}Orders`].total = 0;
    },
    [purchaseOrdersTypes.GET_HISTORY_PENDING]: (state, value) => {
      state.getHistory.pending = value;
    },
    [purchaseOrdersTypes.GET_HISTORY]: (state, history) => {
      state.purchaseHistory = { ...history };
    },
    [purchaseOrdersTypes.SAVE_AWAITING_VALIDATION_QUANTITY]: (state, { uuid, productOrderUuid, quantity }) => {
      if (!(uuid in state.awaitingValidationPurchaseQuantities)) {
        state.awaitingValidationPurchaseQuantities[uuid] = [];
      }
      let found = state.awaitingValidationPurchaseQuantities[uuid].find(
        (p) => p.product_order_uuid === productOrderUuid,
      );
      if (found) {
        found.quantity = quantity;
      } else {
        state.awaitingValidationPurchaseQuantities[uuid].push({
          product_order_uuid: productOrderUuid,
          quantity: quantity,
          validated_by: 'KITCHEN_MANAGER',
        });
      }
    },
    [purchaseOrdersTypes.REMOVE_AWAITING_VALIDATION_QUANTITY]: (state, { uuid }) => {
      if (uuid in state.awaitingValidationPurchaseQuantities) {
        delete state.awaitingValidationPurchaseQuantities[uuid];
      }
    },
  },
  actions: {
    fetchUpcomingPurchaseOrders(_context, opts) {
      return gatewaySupplyApi.fetchUpcomingPurchaseOrders(opts);
    },
    reorderCancelledAwaitingValidationOrder(_context, uuid) {
      return purchaseOrdersApi.reorderCancelledAwaitingValidationOrder(uuid);
    },
    fetchLocalizedProductAvailableSubstitutes(_context, { productUuid, locationUuid, queryParams }) {
      return gatewaySupplyApi.fetchLocalizedProductAvailableSubstitutes(productUuid, locationUuid, queryParams);
    },
    createProductOrderForAwaitingDeliveryOrder(_content, { purchaseUuid, bodyRequest }) {
      return gatewaySupplyApi.createProductOrderForAwaitingDeliveryOrder(purchaseUuid, bodyRequest);
    },
    createProductOrderForAwaitingValidationOrder(_context, { purchaseUuid, bodyRequest }) {
      return gatewaySupplyApi.createProductOrderForAwaitingValidationOrder(purchaseUuid, bodyRequest);
    },
    async fetchGatewayAwaitingValidationPurchaseOrder({ commit }, { uuid }) {
      try {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING, true);
        const purchase = await gatewaySupplyApi.fetchAwaitingValidationPurchaseOrder(uuid);
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE, { purchase, status: purchase.status });
        return purchase;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING, false);
      }
    },
    async fetchAllPurchaseOrders({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_PURCHASES_PENDING, true);
        let purchases = await purchaseOrdersApi.fetchAll(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_PURCHASES, purchases);
        return purchases;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_PURCHASES_PENDING, false);
      }
    },
    async fetchOne({ commit }, { uuid, ignoreZeroValidatedQuantities = false }) {
      try {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING, true);
        const purchase = await purchaseOrdersApi.fetchOne(uuid, ignoreZeroValidatedQuantities);
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE, { purchase, status: purchase.status });
        return purchase;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING, false);
      }
    },
    async fetchOneByStatus({ state, commit, getters }, { uuid, status, ignoreZeroValidatedQuantities = false }) {
      try {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING, true);
        let request = getters.getFetchRequestByStatus(status);
        let purchase = [state.statuses.VALIDATED, state.statuses.AWAITING_DELIVERY, state.statuses.DELIVERED].includes(
          status,
        )
          ? await request(uuid, { ignoreZeroValidatedQuantities })
          : await request(uuid);
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE, { purchase, status });
        return purchase;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_PENDING, false);
      }
    },
    async fetchTasksByStatus({ commit, getters }, { uuid, status, withJobs = true }) {
      commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_TASKS_PENDING, true);

      try {
        const request = getters.getFetchTasksRequestByStatus(status);

        const res = await request(uuid, withJobs);
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_TASKS, res.items);

        return res.items;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ONE_PURCHASE_TASKS_PENDING, false);
      }
    },
    async fetchAllDelivers({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_DELIVERS_PENDING, true);
        let delivers = await purchaseOrdersApi.fetchDelivers(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_DELIVERS, delivers);
        return delivers;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_DELIVERS_PENDING, false);
      }
    },
    unsetCurrentPurchase({ commit }) {
      commit(purchaseOrdersTypes.UNSET_CURRENT_PURCHASE);
    },
    async generatePurchaseOrder({ state }, { purchaseOrderUuid, status }) {
      let request = null;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.generateDraftedOrder;
          break;
        case state.statuses.SUGGESTED:
          request = purchaseOrdersApi.generateSuggestedOrder;
          break;
        default:
          throw Error(`Unable to generate status: ${status}`);
      }
      return await request(purchaseOrderUuid);
    },
    async generateProductOrder({ state }, { purchaseOrderUuid, productOrderUuid, status }) {
      let request = null;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.generateDraftedProductdOrder;
          break;
        case state.statuses.SUGGESTED:
          request = purchaseOrdersApi.generateSuggestedProductdOrder;
          break;
        default:
          throw Error(`Unable to generate status: ${status}`);
      }
      return await request(purchaseOrderUuid, productOrderUuid);
    },
    async fetchAllDrafted({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_DRAFTED_ORDERS_PENDING, true);
        let drafted = await purchaseOrdersApi.fetchDraftedOrders(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_DRAFTED_ORDERS, drafted);
        return drafted;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_DRAFTED_ORDERS_PENDING, false);
      }
    },
    async fetchAllSuggested({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_SUGGESTED_ORDERS_PENDING, true);
        let suggested = await purchaseOrdersApi.fetchSuggestedOrders(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_SUGGESTED_ORDERS, suggested);
        return suggested;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_SUGGESTED_ORDERS_PENDING, false);
      }
    },
    async fetchAllAwaitingValidation({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_AWAITING_VALIDATION_ORDERS_PENDING, true);
        let awaitingValidation = await purchaseOrdersApi.fetchAwaitingValidationOrders(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_AWAITING_VALIDATION_ORDERS, awaitingValidation);
        return awaitingValidation;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_AWAITING_VALIDATION_ORDERS_PENDING, false);
      }
    },
    async fetchNumberOfOrdersToValidate({ commit }, { locationUuid }) {
      const purchases = await purchaseOrdersApi.fetchAwaitingValidationOrders({
        locationUuid,
        withValidationOverdue: false,
      });
      commit(purchaseOrdersTypes.FETCH_NUMBER_OF_ORDERS_TO_VALIDATE, purchases.total);
    },
    async fetchAllValidated({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_VALIDATED_ORDERS_PENDING, true);
        let validated = await purchaseOrdersApi.fetchValidatedOrders(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_VALIDATED_ORDERS, validated);
        return validated;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_VALIDATED_ORDERS_PENDING, false);
      }
    },
    async fetchAllAwaitingDelivery({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_AWAITING_DELIVERY_ORDERS_PENDING, true);
        let awaitingDelivery = await purchaseOrdersApi.fetchAwaitingDeliveryOrders(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_AWAITING_DELIVERY_ORDERS, awaitingDelivery);
        return awaitingDelivery;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_AWAITING_DELIVERY_ORDERS_PENDING, false);
      }
    },
    async fetchAllDelivered({ commit }, opts) {
      try {
        commit(purchaseOrdersTypes.FETCH_ALL_DELIVERED_ORDERS_PENDING, true);
        let delivered = await purchaseOrdersApi.fetchDeliveredOrders(opts);
        commit(purchaseOrdersTypes.FETCH_ALL_DELIVERED_ORDERS, delivered);
        return delivered;
      } finally {
        commit(purchaseOrdersTypes.FETCH_ALL_DELIVERED_ORDERS_PENDING, false);
      }
    },
    async fetchMoreOrders({ commit, getters }, { status, opts }) {
      const request = getters.getFetchAllRequestByStatus(status);
      const purchaseOrders = await request(opts);

      commit(purchaseOrdersTypes.FETCH_MORE, { status, purchaseOrders });
      return purchaseOrders;
    },
    resetOrdersByStatus({ commit }, status) {
      commit(purchaseOrdersTypes.RESET_ORDERS_LIST, status);
    },
    async updateProductByStatus({ getters }, { status, purchaseOrderUuid, productOrderUuid, payload }) {
      const request = getters.getUpdateProductRequestByStatus(status);
      return await request(purchaseOrderUuid, productOrderUuid, payload);
    },
    async updatePurchaseByStatus({ getters }, { status, purchaseOrderUuid, payload }) {
      const request = getters.getUpdateRequestByStatus(status);
      return await request(purchaseOrderUuid, payload);
    },
    updatePurchaseDeliveryCharges(_, { uuid, charges }) {
      return purchaseOrdersApi.updateDeliveryCharges(uuid, charges);
    },
    async createPurchaseOrderByStatus({ getters }, { status, payload }) {
      const request = getters.getCreateRequestByStatus(status);
      return await request(payload);
    },
    async createProductOrderByStatus({ getters }, { status, purchaseOrderUuid, payload }) {
      const request = getters.getCreateProductRequestByStatus(status);
      return await request(purchaseOrderUuid, payload);
    },
    async sendPurchaseOrderByStatus({ getters }, { status, purchaseOrderUuid }) {
      const request = getters.sendRequestByStatus(status);
      return await request(purchaseOrderUuid);
    },
    cancelPurchaseOrderByStatus({ getters }, { status, uuid, message }) {
      return getters.cancelRequestByStatus(status)(uuid, message);
    },
    cancelPurchaseDeliveryCharges(_, { uuid }) {
      return purchaseOrdersApi.cancelDeliveryCharges(uuid);
    },
    checkPurchaseOrderByStatus({ getters }, { status, uuid, overloads = undefined, strictMode = true }) {
      return getters.checkPurchaseOrderByStatus(status)(uuid, overloads, strictMode);
    },
    async changeStatus(
      { state, getters },
      { purchaseOrderUuid, fromStatus, toStatus, fillZeroQuantities = false, fillUncompleted = false },
    ) {
      const request = getters.getChangeStatusRequest(fromStatus, toStatus);

      if (fromStatus === state.statuses.AWAITING_DELIVERY && toStatus === state.statuses.DELIVERED) {
        return await request(purchaseOrderUuid, fillZeroQuantities, fillUncompleted);
      }

      return request(purchaseOrderUuid, fillUncompleted);
    },
    async uploadDeliveryNote({ getters }, { status, purchaseOrderUuid, deliverLabel, deliveryNotes, kitchenId }) {
      const request = getters.getUploadRequestByStatus(status);
      return await request(purchaseOrderUuid, deliverLabel, deliveryNotes, kitchenId);
    },
    delayAwaitingValidationOrder(_, { purchaseOrderUuid, payload }) {
      return purchaseOrdersApi.delayAwaitingValidationOrder(purchaseOrderUuid, payload);
    },
    advanceAwaitingValidationOrder(_, { purchaseOrderUuid, payload }) {
      return purchaseOrdersApi.advanceAwaitingValidationOrder(purchaseOrderUuid, payload);
    },
    uploadProductOrderDamageProof({ getters }, { status, purchaseOrderUuid, productOrderUuid, damageProof }) {
      const request = getters.getUploadDamageProofRequestByStatus(status);

      return request(purchaseOrderUuid, productOrderUuid, damageProof);
    },
    saveAwaitingValidationQuantity({ commit }, { uuid, productOrderUuid, quantity }) {
      commit(purchaseOrdersTypes.SAVE_AWAITING_VALIDATION_QUANTITY, { uuid, productOrderUuid, quantity });
    },
    removeAwaitingValidationQuantity({ commit }, { uuid }) {
      commit(purchaseOrdersTypes.REMOVE_AWAITING_VALIDATION_QUANTITY, { uuid });
    },
    async updateAwaitingValidationProductQuantities(_, { uuid, payload }) {
      return await purchaseOrdersApi.updateAwaitingValidationProductQuantities(uuid, payload);
    },
    async updateDeliveredProductPrice(_, { purchaseOrderUuid, productOrderUuid, payload }) {
      return await purchaseOrdersApi.updateDeliveredProductPrice(purchaseOrderUuid, productOrderUuid, payload);
    },
    async exportData(_, payload) {
      return await purchaseOrdersApi.exportData(payload);
    },
    async getPurchaseHistory({ commit }, { locations, opts }) {
      try {
        commit(purchaseOrdersTypes.GET_HISTORY_PENDING, true);
        const history = await purchaseOrdersApi.getHistory(locations, opts);
        commit(purchaseOrdersTypes.GET_HISTORY, history);

        return history;
      } finally {
        commit(purchaseOrdersTypes.GET_HISTORY_PENDING, false);
      }
    },
    async deletePurchaseOrderByStatus({ getters }, { uuid, status }) {
      const request = getters.getDeleteRequestByStatus(status);
      return await request(uuid);
    },
    async generateValidatedPDF(_, { purchaseOrderUuid, sendAfter }) {
      return await purchaseOrdersApi.generatePDF(purchaseOrderUuid, sendAfter);
    },
    async sendMailValidated(_, { purchaseOrderUuid }) {
      return await purchaseOrdersApi.sendMail(purchaseOrderUuid);
    },
    async saveDraftMailForDeliveredOrders(_, { purchaseOrderUuid }) {
      return await purchaseOrdersApi.saveDraftMailForDeliveredOrders(purchaseOrderUuid);
    },
    changePurchaseFilters({ state }, { field, value }) {
      state.searchListPurchaseFilters[field] = value
    },
  },
  getters: {
    getPurchaseOrdersByStatus: (state) => (status) => {
      switch (status) {
        case state.statuses.DRAFTED:
          return state.draftedOrders.items;
        case state.statuses.SUGGESTED:
          return state.suggestedOrders.items;
        case state.statuses.AWAITING_VALIDATION:
          return state.awaitingValidationOrders.items;
        case state.statuses.VALIDATED:
          return state.validatedOrders.items;
        case state.statuses.AWAITING_DELIVERY:
          return state.awaitingDeliveryOrders.items;
        case state.statuses.DELIVERED:
          return state.deliveredOrders.items;
        default:
          return state.purchaseOrders.items;
      }
    },
    getFetchAllRequestByStatus: (state) => (status) => {
      let request = purchaseOrdersApi.fetchAll;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.fetchDraftedOrders;
          break;
        case state.statuses.SUGGESTED:
          request = purchaseOrdersApi.fetchSuggestedOrders;
          break;
        case state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.fetchAwaitingValidationOrders;
          break;
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.fetchValidatedOrders;
          break;
        case state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.fetchAwaitingDeliveryOrders;
          break;
        case state.statuses.DELIVERED:
          request = purchaseOrdersApi.fetchDeliveredOrders;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    getFetchRequestByStatus: (state) => (status) => {
      let request = purchaseOrdersApi.fetchOne;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.fetchDraftedOrder;
          break;
        case state.statuses.SUGGESTED:
          request = purchaseOrdersApi.fetchSuggestedOrder;
          break;
        case state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.fetchAwaitingValidationOrder;
          break;
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.fetchValidatedOrder;
          break;
        case state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.fetchAwaitingDeliveryOrder;
          break;
        case state.statuses.DELIVERED:
          request = purchaseOrdersApi.fetchDeliveredOrder;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    getFetchTasksRequestByStatus: (state) => (status) => {
      switch (status) {
        case state.statuses.DRAFTED:
          return purchaseOrdersApi.fetchDraftedOrderTasks;
        case state.statuses.SUGGESTED:
          return purchaseOrdersApi.fetchSuggestedOrderTasks;
        case state.statuses.AWAITING_VALIDATION:
          return purchaseOrdersApi.fetchAwaitingValidationOrderTasks;
        case state.statuses.VALIDATED:
          return purchaseOrdersApi.fetchValidatedOrderTasks;
        case state.statuses.AWAITING_DELIVERY:
          return purchaseOrdersApi.fetchAwaitingDeliveryOrderTasks;
        case state.statuses.DELIVERED:
          return purchaseOrdersApi.fetchDeliveredOrderTasks;
        default:
          return purchaseOrdersApi.fetchOneTasks;
      }
    },
    getUpdateRequestByStatus: (state) => (status) => {
      let request = purchaseOrdersApi.updateOne;
      switch (status) {
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.updateValidatedOrder;
          break;
        case state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.updateAwaitingValidationOrder;
          break;
        case state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.updateAwaitingDeliveryOrder;
          break;
        case state.statuses.DELIVERED:
          request = purchaseOrdersApi.updateDeliveredOrder;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    getUpdateProductRequestByStatus: (state) => (status) => {
      let request = null;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.updateDraftedProductOrder;
          break;
        case state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.updateAwaitingValidationProductOrder;
          break;
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.updateValidatedProductOrder;
          break;
        case state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.updateAwaitingDeliveryProductOrder;
          break;
        case state.statuses.DELIVERED:
          request = purchaseOrdersApi.updateDeliveredProductOrder;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    getUploadDamageProofRequestByStatus: (state) => (status) => {
      switch (status) {
        case state.statuses.AWAITING_DELIVERY:
          return purchaseOrdersApi.awaitingDeliveryOrdersUploadDamageProof;
        case state.statuses.DELIVERED:
          return purchaseOrdersApi.deliveredOrdersUploadDamageProof;
      }
      throw Error(`Unknow status: ${status}`);
    },
    getCreateRequestByStatus: (state) => (status) => {
      let request = null;
      switch (status) {
        case state.statuses.SUGGESTED:
          request = purchaseOrdersApi.createSuggestedOrder;
          break;
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.createValidatedOrder;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    getCreateProductRequestByStatus: (state) => (status) => {
      let request = null;
      switch (status) {
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.createValidatedProductOrder;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    cancelRequestByStatus: (state) => (status) => {
      switch (status) {
        case state.statuses.AWAITING_VALIDATION:
          return purchaseOrdersApi.cancelAwaitingValidationOrder;
        case state.statuses.VALIDATED:
          return purchaseOrdersApi.cancelValidatedOrder;
      }

      throw new Error(`Unknown status: ${status}`);
    },
    checkPurchaseOrderByStatus: (state) => (status) => {
      if (status !== state.statuses.AWAITING_VALIDATION) {
        throw new Error('Status not yet implemented');
      }

      return purchaseOrdersApi.checkAwaitingValidationOrder;
    },
    sendRequestByStatus: (state) => (status) => {
      let request = null;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.sendDraftedOrder;
          break;
        case state.statuses.VALIDATED:
          request = purchaseOrdersApi.sendValidatedOrder;
          break;
        default:
          throw Error(`Unknown status: ${status}`);
      }
      return request;
    },
    getChangeStatusRequest: (state) => (fromStatus, toStatus) => {
      let request = null;
      switch (fromStatus + '|' + toStatus) {
        case state.statuses.DRAFTED + '|' + state.statuses.SUGGESTED:
          request = purchaseOrdersApi.passDraftedToSuggested;
          break;
        case state.statuses.SUGGESTED + '|' + state.statuses.DRAFTED:
          request = purchaseOrdersApi.passSuggestedToDrafted;
          break;
        case state.statuses.SUGGESTED + '|' + state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.passSuggestedToAwaitValidation;
          break;
        case state.statuses.AWAITING_VALIDATION + '|' + state.statuses.SUGGESTED:
          request = purchaseOrdersApi.passAwaitingValidationToSuggested;
          break;
        case state.statuses.AWAITING_VALIDATION + '|' + state.statuses.VALIDATED:
          request = purchaseOrdersApi.passAwaitingValidationToValidated;
          break;
        case state.statuses.VALIDATED + '|' + state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.passValidatedToAwaitingValidation;
          break;
        case state.statuses.VALIDATED + '|' + state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.passValidatedToAwaitingDelivery;
          break;
        case state.statuses.AWAITING_DELIVERY + '|' + state.statuses.VALIDATED:
          request = purchaseOrdersApi.passAwaitingDeliveryToValidated;
          break;
        case state.statuses.AWAITING_DELIVERY + '|' + state.statuses.DELIVERED:
          request = purchaseOrdersApi.passAwaitingDeliveryToDelivered;
          break;
        case state.statuses.DELIVERED + '|' + state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.passDeliveredToAwaitingDelivery;
          break;
        default:
          throw Error(`Unable to change from ${fromStatus} to ${toStatus}`);
      }
      return request;
    },
    getUploadRequestByStatus: (state) => (status) => {
      let request = null;
      switch (status) {
        case state.statuses.AWAITING_DELIVERY:
          request = purchaseOrdersApi.uploadAwaitingValidationDeliveryNote;
          break;
        case state.statuses.DELIVERED:
          request = purchaseOrdersApi.uploadDeliveredDeliveryNote;
          break;
        default:
          throw Error(`Unable to upload status ${status}`);
      }
      return request;
    },
    getDeleteRequestByStatus: (state) => (status) => {
      let request = null;
      switch (status) {
        case state.statuses.DRAFTED:
          request = purchaseOrdersApi.deleteDraftedOrder;
          break;
        case state.statuses.SUGGESTED:
          request = purchaseOrdersApi.deleteSuggestedOrder;
          break;
        case state.statuses.AWAITING_VALIDATION:
          request = purchaseOrdersApi.deleteAwaitingValidationOrder;
          break;
        default:
          throw Error(`Unable to delete status: '${status}'`);
      }
      return request;
    },
  },
};
