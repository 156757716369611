<template>
  <div class="root p-4">
    <div class="wrapper has-text-centered">
      <div class="is-flex is-flex-direction-row">
        <div>
          <img src="@/assets/images/one-source.png" alt="One Source" />
        </div>
        <div class="is-flex-grow-1 mx-4">
          <img src="@/assets/images/arrow.png" alt="Arrow from OneSource to OneBoard" />
        </div>
        <div>
          <img src="@/assets/images/one-board.png" alt="One Board" />
        </div>
      </div>

      <h1 class="title is-3 m-0">
        {{ this.$t('deprecated.title') }}
      </h1>
      <p class="is-size-6">
        {{ this.$t('deprecated.text') }}
      </p>
      <b-button tag="a" class="is-primary" type="is-taster-blue" size="large" href="https://astro.taster.com">
        {{ this.$t('deprecated.button') }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  max-width: 650px;
}
</style>
