import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const purchaseOrdersApi = new PurchaseOrdersClient.PurchaseOrdersApi();
purchaseOrdersApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  // Purchase Orders
  fetchAll(opts) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersListPurchaseOrders(opts);
  },
  fetchOne(uuid, ignoreZeroValidatedQuantities = false) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersGetPurchaseOrder(uuid, {
      ignoreZeroValidatedQuantities,
    });
  },
  fetchOneTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersListTasks(uuid, { withJobs });
  },
  createOne(purchaseOrder) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersCreatePurchaseOrder(purchaseOrder);
  },
  updateOne(uuid, purchaseOrder) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersUpdatePurchaseOrderById(uuid, purchaseOrder);
  },
  fetchDelivers(opts) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersListPurchaseDelivers(opts);
  },
  exportData(payload) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersExportPurchaseOrders(payload);
  },
  getHistory(locations, opts) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersGetPurchaseOrdersHistory({
      purchaseOrderHistoryRequest: {
        location_uuids: locations,
        ...opts,
      },
    });
  },
  updateDeliveryCharges(uuid, charges) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersUpdatePurchaseOrderDeliveryCharges(uuid, {
      updatePurchaseOrderDeliveryChargesRequest: {
        delivery_charges: charges,
      },
    });
  },
  cancelDeliveryCharges(uuid) {
    return clientHook(purchaseOrdersApi).appApisV1PurchaseOrdersCancelPurchaseOrderDeliveryCharges(uuid);
  },
  // Drafted Orders
  fetchDraftedOrders(opts) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersListDraftedOrders(opts);
  },
  fetchDraftedOrder(opts) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersGetDraftedOrder(opts);
  },
  fetchDraftedOrderTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersListTasks(uuid, { withJobs });
  },
  updateDraftedProductOrder(purchaseOrderUuid, productOrderUuid, payload) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersUpdateDraftedProduct(
      purchaseOrderUuid,
      productOrderUuid,
      payload,
    );
  },
  deleteDraftedOrder(draftedOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersDeleteDraftedOrder(draftedOrderUuid);
  },
  generateDraftedOrder(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersGenerateDraftedOrder(purchaseOrderUuid);
  },
  generateDraftedProductdOrder(purchaseOrderUuid, productOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersGenerateDraftedProduct(
      purchaseOrderUuid,
      productOrderUuid,
    );
  },
  sendDraftedOrder(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersSendDraftedOrder(purchaseOrderUuid);
  },
  passDraftedToSuggested(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DraftedOrdersPassDraftedToSuggested(purchaseOrderUuid);
  },
  // Suggested Orders
  fetchSuggestedOrders(opts) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersListSuggestedOrders(opts);
  },
  fetchSuggestedOrder(opts) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersGetSuggestedOrder(opts);
  },
  fetchSuggestedOrderTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersListTasks(uuid, { withJobs });
  },
  createSuggestedOrder(opts) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersCreateSuggestedOrder(opts);
  },
  deleteSuggestedOrder(suggestedOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersDeleteSuggestedOrder(suggestedOrderUuid);
  },
  generateSuggestedOrder(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersGenerateSuggestedOrder(purchaseOrderUuid);
  },
  generateSuggestedProductdOrder(purchaseOrderUuid, productOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersGenerateSuggestedProduct(
      purchaseOrderUuid,
      productOrderUuid,
    );
  },
  passSuggestedToDrafted(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersPassSuggestedToDrafted(purchaseOrderUuid);
  },
  passSuggestedToAwaitValidation(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1SuggestedOrdersPassSuggestedToAwaitingValidation(purchaseOrderUuid);
  },
  // Awaiting validation Orders
  reorderCancelledAwaitingValidationOrder(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersReorderAwaitingValidationOrder(
      purchaseOrderUuid,
    );
  },
  fetchAwaitingValidationOrders(opts) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersListAwaitingValidationOrders(opts);
  },
  fetchAwaitingValidationOrder(opts) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersGetAwaitingValidationOrder(opts);
  },
  fetchAwaitingValidationOrderTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersListTasks(uuid, { withJobs });
  },
  delayAwaitingValidationOrder(purchaseOrderUuid, opts) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersDelayAwaitingDeliveryOrder(
      purchaseOrderUuid,
      opts,
    );
  },
  advanceAwaitingValidationOrder(purchaseOrderUuid, opts) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersAdvanceAwaitingDeliveryOrder(
      purchaseOrderUuid,
      opts,
    );
  },
  updateAwaitingValidationProductOrder(purchaseOrderUuid, productOrderUuid, productOrder) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersUpdateAwaitingValidationProduct(
      purchaseOrderUuid,
      productOrderUuid,
      productOrder,
    );
  },
  deleteAwaitingValidationOrder(awaitingValidationOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersDeleteAwaitingValidationOrder(
      awaitingValidationOrderUuid,
    );
  },
  cancelAwaitingValidationOrder(purchaseOrderUuid, message = null) {
    const opts = {};
    if (message) {
      opts.validatedOrderCancelRequest = { message };
    }

    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersCancelAwaitingValidationOrder(
      purchaseOrderUuid,
      opts,
    );
  },
  checkAwaitingValidationOrder(purchaseOrderUuid, overloads = undefined, strictMode = false) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersCheckAwaitingValidationOrder(
      purchaseOrderUuid,
      {
        purchaseOrderCheckRequest: {
          quantity_overloads: overloads,
          strict_mode: strictMode,
        },
      },
    );
  },
  passAwaitingValidationToSuggested(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersPassAwaitingValidationToSuggested(
      purchaseOrderUuid,
    );
  },
  passAwaitingValidationToValidated(purchaseOrderUuid, fillUncompleted = false) {
    const opts = {
      validatePurchaseOrderRequest: {
        fill_uncompleted: fillUncompleted,
      },
    };

    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersPassAwaitingValidationToValidated(
      purchaseOrderUuid,
      opts,
    );
  },
  updateAwaitingValidationOrder(uuid, { validationDueDate }) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersUpdateAwaitingValidationOrder(uuid, {
      validation_due_date: validationDueDate,
    });
  },
  updateAwaitingValidationProductQuantities(purchaseOrderUuid, payload) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingValidationOrdersUpdateAwaitingValidationProductQuantities(
      purchaseOrderUuid,
      payload,
    );
  },
  // Validated Orders
  fetchValidatedOrders(opts) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersListValidatedOrders(opts);
  },
  fetchValidatedOrder(uuid, opts) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersGetValidatedOrder(uuid, opts);
  },
  fetchValidatedOrderTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersListTasks(uuid, { withJobs });
  },
  createValidatedOrder(purchaseOrder) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersCreateValidatedOrder(purchaseOrder);
  },
  updateValidatedOrder(uuid, purchaseOrder) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersUpdateValidatedOrder(uuid, purchaseOrder);
  },
  createValidatedProductOrder(uuid, productOrder) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersCreateValidatedProduct(uuid, productOrder);
  },
  validatedOrderToAwaitingDelivery(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersPassValidatedToAwaitingDelivery(purchaseOrderUuid);
  },
  updateValidatedProductOrder(purchaseOrderUuid, productOrderUuid, productOrder) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersUpdateValidatedProduct(
      purchaseOrderUuid,
      productOrderUuid,
      productOrder,
    );
  },
  cancelValidatedOrder(purchaseOrderUuid, message = null) {
    const opts = {};
    if (message) {
      opts.validatedOrderCancelRequest = { message };
    }

    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersCancelValidatedOrder(purchaseOrderUuid, opts);
  },
  sendValidatedOrder(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersSendValidatedOrder(purchaseOrderUuid);
  },
  passValidatedToAwaitingValidation(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersPassValidatedToAwaitingValidation(purchaseOrderUuid);
  },
  passValidatedToAwaitingDelivery(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersPassValidatedToAwaitingDelivery(purchaseOrderUuid);
  },
  generatePDF(purchaseOrderUuid, sendAfter) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersGeneratePdf(purchaseOrderUuid, sendAfter);
  },
  sendMail(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1ValidatedOrdersSendMail(purchaseOrderUuid);
  },
  // Awaiting delivery Orders
  fetchAwaitingDeliveryOrders(opts) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersListAwaitingDeliveryOrders(opts);
  },
  fetchAwaitingDeliveryOrder(uuid, opts) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersGetAwaitingDeliveryOrder(uuid, opts);
  },
  fetchAwaitingDeliveryOrderTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersListTasks(uuid, { withJobs });
  },
  updateAwaitingDeliveryOrder(uuid, purchaseOrder) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersUpdateAwaitingDeliveryOrder(
      uuid,
      purchaseOrder,
    );
  },
  awaitingDeliveryUploadDeliveryNote(uuid, deliverLabel, deliveryNotes, kitchenId) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersUploadDeliveryNote(
      uuid,
      deliverLabel,
      deliveryNotes,
      kitchenId,
    );
  },
  awaitingDeliveryOrdersUploadDamageProof(purchaseOrderUuid, productOrderUuid, damageProof) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersUploadDamageProof(
      purchaseOrderUuid,
      productOrderUuid,
      damageProof,
    );
  },
  awaitingDeliveryOrderToValidated(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersPassAwaitingDeliveryToValidated(
      purchaseOrderUuid,
    );
  },
  awaitingDeliveryOrderToDelivered(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersPassAwaitingDeliveryToDelivered(
      purchaseOrderUuid,
    );
  },
  updateAwaitingDeliveryProductOrder(purchaseOrderUuid, productOrderUuid, productOrder) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersUpdateAwaitingDeliveryProduct(
      purchaseOrderUuid,
      productOrderUuid,
      productOrder,
    );
  },
  passAwaitingDeliveryToValidated(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersPassAwaitingDeliveryToValidated(
      purchaseOrderUuid,
    );
  },
  passAwaitingDeliveryToDelivered(purchaseOrderUuid, fillZeroQuantities = false, fillUncompleted = false) {
    const opts = {
      awaitingDeliveryDeliverRequest: {
        fill_zero_quantities: fillZeroQuantities,
        fill_uncompleted: fillUncompleted,
      },
    };
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersPassAwaitingDeliveryToDelivered(
      purchaseOrderUuid,
      opts,
    );
  },
  uploadAwaitingValidationDeliveryNote(uuid, deliverLabel, deliveryNotes, kitchenId) {
    return clientHook(purchaseOrdersApi).appApisV1AwaitingDeliveryOrdersUploadDeliveryNote(
      uuid,
      deliverLabel,
      deliveryNotes,
      kitchenId,
    );
  },
  // Delivered Orders
  fetchDeliveredOrders(opts) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersListDeliveredOrders(opts);
  },
  fetchDeliveredOrder(uuid, opts) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersGetDeliveredOrder(uuid, opts);
  },
  fetchDeliveredOrderTasks(uuid, withJobs = false) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersListTasks(uuid, { withJobs });
  },
  updateDeliveredOrder(uuid, purchaseOrder) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersUpdateDeliveredOrder(uuid, purchaseOrder);
  },
  uploadDeliveredDeliveryNote(uuid, deliverLabel, deliveryNotes, kitchenId) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersUploadDeliveryNote(
      uuid,
      deliverLabel,
      deliveryNotes,
      kitchenId,
    );
  },
  deliveredOrdersUploadDamageProof(purchaseOrderUuid, productOrderUuid, damageProof) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersUploadDamageProof(
      purchaseOrderUuid,
      productOrderUuid,
      damageProof,
    );
  },
  updateDeliveredProductOrder(purchaseOrderUuid, productOrderUuid, productOrder) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersUpdateDeliveredProduct(
      purchaseOrderUuid,
      productOrderUuid,
      productOrder,
    );
  },
  passDeliveredToAwaitingDelivery(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersPassDeliveredToAwaitingDelivery(purchaseOrderUuid);
  },
  updateDeliveredProductPrice(purchaseOrderUuid, productOrderUuid, payload) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersUpdateDeliveredProductPrice(
      purchaseOrderUuid,
      productOrderUuid,
      payload,
    );
  },
  saveDraftMailForDeliveredOrders(purchaseOrderUuid) {
    return clientHook(purchaseOrdersApi).appApisV1DeliveredOrdersSaveDraftFromDeliveredOrder(purchaseOrderUuid)
  }
};
