import restaurantsApi from '@/apis/purchaseOrders/restaurants';

export default {
    state: {
    },
    mutations: {
    },
    actions: {
        async getCurrentShift(_, { uuid }) {
            return await restaurantsApi.getCurrentShift(uuid)
        },
    },
};
