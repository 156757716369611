import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const productsApi = new PurchaseOrdersClient.ProductsApi();
productsApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  fetchAllSuppliers(opts) {
    return clientHook(productsApi).appApisV1ProductsListSuppliers(opts);
  },
  fetchAllHubs(opts) {
    return clientHook(productsApi).appApisV1ProductsListHubs(opts);
  },
  fetchAllProducts(opts) {
    return clientHook(productsApi).appApisV1ProductsListProducts(opts);
  },
  fetchAllCategories(opts) {
    return clientHook(productsApi).appApisV1ProductsListCategories(opts);
  },
  fetchOneSupplier(supplierUuid, opts) {
    return clientHook(productsApi).appApisV1ProductsGetSupplier(supplierUuid, opts);
  },
  fetchOneHub(hubUuid, opts) {
    return clientHook(productsApi).appApisV1ProductsGetHub(hubUuid, opts);
  },
};
