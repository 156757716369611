<template>
  <b-select class="select-language" v-model="modelLocale">
    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.value">
      <span>{{ lang.icon }}</span>
    </option>
  </b-select>
</template>

<script>
export default {
  name: 'LocaleSelector',
  data() {
    return {
      langs: [
        { value: 'en', icon: '🇬🇧' },
        { value: 'es', icon: '🇪🇸' },
        { value: 'fr', icon: '🇫🇷' },
        { value: 'nl', icon: '🇳🇱' },
      ],
    };
  },
  computed: {
    modelLocale: {
      get() {
        return this.$root.$i18n.locale;
      },
      set(localeValue) {
        this.$root.$i18n.locale = localeValue;
        this.$dayjs.locale(localeValue);
        localStorage.setItem('selectedLanguage', localeValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/customColors';

.select-language {
  ::v-deep .select {
    &::after {
      border-color: $taster-blue !important;
    }
  }
}
</style>
