import { mapState, mapActions, mapGetters } from 'vuex';

export const productsMgr = {
  computed: {
    ...mapState({
      products: (state) => state.products.products,
      hubs: (state) => state.products.hubs,
      suppliers: (state) => state.products.suppliers,
      categories: (state) => state.products.categories,
      fetchSuppliersLoading: (state) => state.products.fetchAllSuppliers.pending,
      fetchHubsLoading: (state) => state.products.fetchAllHubs.pending,
      fetchProductsLoading: (state) => state.products.fetchAllProducts.pending,
      fetchCategoriesLoading: (state) => state.products.fetchAllCategories.pending,
    }),
    ...mapGetters('products', [
      'getDeliverByUuidAndType',
      'getProductByUuid',
      'productsKeyByUuid',
      'getCategoryByUuid',
    ]),
  },
  methods: {
    ...mapActions('products', {
      fetchAllSuppliers: 'fetchAllSuppliers',
      fetchAllHubs: 'fetchAllHubs',
      fetchAllProducts: 'fetchAllProducts',
      fetchAllCategories: 'fetchAllCategories',
      fetchOneDeliver: 'fetchOneDeliver',
    }),
    ...mapActions(['setError', 'processError']),
    async fetchSuppliers(force) {
      if ((!this.fetchSuppliersLoading && this.suppliers.length === 0) || force) {
        try {
          return await this.fetchAllSuppliers({ allSuppliers: true });
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchHubs(force) {
      if ((!this.fetchHubsLoading && this.hubs.length === 0) || force) {
        try {
          return await this.fetchAllHubs();
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchProducts(opts) {
      const force = opts ? opts.force : undefined;
      const locationUuid = opts ? opts.locationUuid : undefined;
      if (!this.fetchProductsLoading || force) {
        try {
          return await this.fetchAllProducts({ locationUuid });
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchCategories(force) {
      if ((!this.fetchCategoriesLoading && this.categories.length === 0) || force) {
        try {
          return await this.fetchAllCategories({ summary: true });
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
  },
};
