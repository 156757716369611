import locations from "./locations"
import kitchens from "./kitchens"

export default {
    namespaced: true,
    state: {
        ...locations.state,
        ...kitchens.state,
    },
    mutations: {
        ...locations.mutations,
        ...kitchens.mutations,
    },
    actions: {
        ...locations.actions,
        ...kitchens.actions,
    },
    getters: {
        ...locations.getters,
    },
}