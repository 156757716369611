import StackdriverErrorReporter from 'stackdriver-errors-js';

const errorHandler = new StackdriverErrorReporter();

const key = process.env.VUE_APP_STACKDRIVER_KEY;
const projectId = process.env.VUE_APP_STACKDRIVER_PROJECT_ID;
const service = process.env.VUE_APP_NAME;
const version = process.env.VUE_APP_VERSION;
const enabled = process.env.VUE_APP_STACKDRIVER_ENABLED;

errorHandler.start({
  key: key,
  projectId: projectId,
  service: service,
  version: version,
  reportUncaughtExceptions: true,
  reportUnhandledPromiseRejections: true,
  disabled: enabled !== 'true',
});

export default {
  install: function(Vue) {
    Vue.config.errorHandler = function(err) {
      let error = err;
      // if not an error object, stringify content (default behavior)
      if (!(err instanceof Error) || !(typeof err != Error)) {
        error = new Error(JSON.stringify(err));
      }
      // if an object, gather information about error
      // message, stack are present in error objects
      // err.error is present with XMLHttpRequest response
      if (err instanceof Object || typeof err === 'object') {
        let message = err.message;
        let stack = err.stack;
        if (err.error) {
          message = `${err.error.message} (${err.error.url})`;
          stack = err.error.stack;
          error.status = err.error.status;
          error.method = err.error.method;
        }
        error.message = message;
        error.stack = stack;
      }
      errorHandler.report(error);
    };
    Vue.prototype.$errorReporting = errorHandler;
  },
};
