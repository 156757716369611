<template>
  <div class="home" v-if="currentLocation && !error">
    <section class="container is-absolute">
      <div v-if="isSupplyAdmin || isController || isSupplyOrderValidator || isStockViewer" class="mx-3">
        <div class="title">{{ $t('home.myKitchen') }}</div>
        <div class="subtitle">{{ $t('home.myKitchenMessage') }}</div>
        <KitchenHome :hero="false" />
      </div>
      <div
        v-if="
          isSupplyAdmin ||
            isSupplyPurchaseHistoryViewer ||
            isSupplyInventoryViewer ||
            isSupplyInventoryEditor ||
            isSupplyTemperatureEditor
        "
        class="mx-3"
      >
        <div class="title">{{ $t('home.admin') }}</div>
        <div class="subtitle">{{ $t('home.adminMessage') }}</div>
        <AdminHome :hero="false" />
      </div>
      <div v-if="!isAuthorized">
        <b-message title="🚫" type="is-taster-blue" :closable="false">
          {{ $t('home.notAuthorized') }}
        </b-message>
      </div>
    </section>
  </div>
</template>

<script>
import AdminHome from '@/views/Admin/Home';
import KitchenHome from '@/views/KitchenTools/Home';
import { hasRole } from '@/router/roles';
import { restaurantsMgr, errorHandler, purchaseOrdersMgr } from '@/mixins';

export default {
  mixins: [restaurantsMgr, errorHandler, purchaseOrdersMgr],
  components: { AdminHome, KitchenHome },
  computed: {
    isController() {
      return hasRole('SupplyController');
    },
    isStockViewer() {
      return hasRole('StockInViewer') || hasRole('StockOutViewer') || hasRole('StockTransferViewer');
    },
    isSupplyAdmin() {
      return hasRole('SupplyAdmin');
    },
    isSupplyOrderValidator() {
      return hasRole('SupplyOrderValidator');
    },
    isSupplyPurchaseHistoryViewer() {
      return hasRole('SupplyPurchaseHistoryViewer');
    },
    isSupplyInventoryViewer() {
      return hasRole('SupplyInventoryViewer');
    },
    isSupplyInventoryEditor() {
      return hasRole('SupplyInventoryEditor');
    },
    isSupplyTemperatureEditor() {
      return hasRole('SupplyTemperatureEditor');
    },
    isAuthorized() {
      return (
        this.isController ||
        this.isSupplyAdmin ||
        this.isSupplyOrderValidator ||
        this.isSupplyPurchaseHistoryViewer ||
        this.isSupplyInventoryViewer ||
        this.isSupplyInventoryEditor ||
        this.isSupplyTemperatureEditor
      );
    },
  },
  methods: {
    fetchPurchases() {
      if (this.currentLocation) {
        if (this.isController || this.isSupplyAdmin) {
          this.fetchAwaitingDelivery({ locationUuid: this.currentLocation.uuid });
        }
        if (this.isSupplyOrderValidator || this.isSupplyAdmin) {
          this.fetchAwaitingValidation({ locationUuid: this.currentLocation.uuid });
        }
      }
    },
  },
  created() {
    this.fetchPurchases();
  },
};
</script>
<style lang="scss" scoped>
.notification-tag {
  position: absolute;
  top: -10px;
  right: -10px;
}
.had-cursor-pointer {
  cursor: pointer;
}
.had-cursor-pointer:hover {
  box-shadow: 0 4px 6px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
</style>
