import Vue from 'vue';

export const groups = {
  ValidationV2: 'One Source - Order Validation',
  ValidationV3: 'One Source - Order Validation v3',
  FranchiseCooks: 'Franchise Cooks',
  TasterCooks: 'Taster Cooks',
  KitchenManagers: 'Kitchen Managers',
  FranchiseManagers: 'Franchise Managers',
};

/**
 * Check if the current user is belonging to a specific IAM group.
 * @param {string} group The group to whom is supposed to belongs the user
 * @returns {bool}
 */
export function belongsTo(group) {
  return Vue.prototype.$auth.userGroups.includes(group);
}
