import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Buefy from 'buefy';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import calendar from 'dayjs/plugin/calendar';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import auth from '@/auth';
import errorReporting from '@/errorReporting';
import './assets/scss/app.scss';
import 'animate.css/animate.min.css';
import './registerServiceWorker';
import './validation';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';
import VueOffline from 'vue-offline';

import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';
import dataLayer from './dataLayer';

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(duration);

dayjs.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[last] dddd',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
});
dayjs.updateLocale('en-gb', {
  calendar: {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[last] dddd',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
});
dayjs.updateLocale('fr', {
  calendar: {
    lastDay: '[Hier]',
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain]',
    lastWeek: 'dddd [dernier]',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
});
dayjs.updateLocale('es', {
  calendar: {
    lastDay: '[Ayer]',
    sameDay: '[Hoy]',
    nextDay: '[Mañana]',
    lastWeek: 'dddd [pasado]',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
});

dayjs.updateLocale('nl', {
  calendar: {
    lastDay: '[Gisteren]',
    sameDay: '[Vandaag]',
    nextDay: '[Morgen]',
    lastWeek: '[Afgelopen] dddd',
    nextWeek: 'dddd',
    sameElse: 'L',
  },
});

const selectedLanguage = localStorage.getItem('selectedLanguage');

if (selectedLanguage) {
  dayjs.locale(selectedLanguage);
} else if (navigator.language) {
  const locale = navigator.language.toLowerCase();
  dayjs.locale(locale === 'en-gb' ? locale : locale.split('-')[0]);
} else {
  dayjs.locale(navigator.userLanguage || 'en');
}

Vue.prototype.$dayjs = dayjs;

Vue.use(Buefy);
Vue.use(VueOffline);

Vue.config.productionTip = false;

Vue.use(VueTour);
Vue.use(auth);
Vue.use(errorReporting);
Vue.use(dataLayer);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
