export default {
  install: function(Vue) {
    Vue.prototype.$dataLayerPush = (eventData) => {
      try {
        window.dataLayer.push(eventData);
      } catch (err) {
        Vue.prototype.$errorReporting.report(err);
      }
    };
  },
};
