<template>
  <section class="sidebar-page">
    <b-sidebar
      :can-cancel="false"
      :expand-on-hover="expand"
      :fullwidth="fullwidth"
      :open="!reduce ? opened : true"
      :reduce="reduce"
      type="is-light"
      fullheight
    >
      <div class="sidebar-container">
        <div class="identity mt-4 has-text-centered">
          <span class="circle">
            {{ name }}
          </span>
          <span class="email is-size-7 my-4 is-hidden-desktop">
            {{ email }}
            <b-button size="is-small" @click="$auth.logout" icon-right="arrow-collapse-right">
              logout
            </b-button>
          </span>
        </div>
        <hr class="solid is-hidden-touch" />
        <b-menu v-if="!isDeprecatedUser">
          <b-menu-list>
            <b-menu-item
              :active="$route.name === 'home'"
              icon="home"
              :label="$t('menu.home')"
              tag="router-link"
              :to="{ name: 'home' }"
            />
          </b-menu-list>
        </b-menu>
        <div v-if="!isDeprecatedUser">
          <template v-for="nav in navigation">
            <b-menu-list
              v-if="
                nav.menu &&
                  nav.display &&
                  isAuthorized(nav) &&
                  ((nav.betaGroups || []).length === 0 || belongsToBeta(nav.betaGroups))
              "
              :key="nav.name"
            >
              <template #label>
                <hr class="solid is-hidden-touch" />
                <span class="px-2">
                  {{ $t(`menu.${nav.keyName}`) }}
                </span>
              </template>
              <template v-for="child in nav.children">
                <SideBarItem
                  v-if="
                    child.display &&
                      isAuthorized(child) &&
                      ((child.betaGroups || []).length === 0 || belongsToBeta(child.betaGroups))
                  "
                  :key="child.name"
                  :route="child"
                >
                  <template #decorator v-if="child.name === 'validation'">
                    <b-tag v-if="numberAwaitingOrders" class="is-pulled-right" type="is-taster-blue" rounded>
                      {{ numberAwaitingOrders }}
                    </b-tag>
                  </template>
                </SideBarItem>
              </template>
            </b-menu-list>
          </template>
        </div>
        <p class="version mt-4 has-text-centered">
          Version: <span class="has-text-weight-bold">{{ version }}</span>
        </p>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import { isAuthorized } from '@/router';
import { hasGroup, hasRole } from '@/router/roles';
import SideBarItem from './SideBarItem.vue';

const mediaQuery = window.matchMedia('(min-width: 1024px)');
const version = process.env.VUE_APP_VERSION;

export default {
  name: 'SideBar',
  components: {
    SideBarItem,
  },
  props: {
    navigation: {
      type: Array,
      required: true,
    },
    numberAwaitingOrders: {
      type: Number,
      required: true,
    },
    opened: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expand: true,
      fullwidth: false,
      reduce: true,
    };
  },
  computed: {
    isDeprecatedUser() {
      return !hasRole('OneSourceAccess');
    },

    email() {
      return this.$auth.user ? this.$auth.user.email : null;
    },
    name() {
      if (!this.$auth.user) {
        return null;
      }

      const { given_name: firstName, family_name: familyName } = this.$auth.user;
      const firstChar = firstName.length > 0 ? firstName.charAt(0) : '';

      switch (familyName.length) {
        case 0:
          return firstChar.toUpperCase();
        case 1:
          return `${firstChar.toUpperCase()}${familyName.toUpperCase()}`;
        default:
          return `${firstChar.toUpperCase()}${familyName.slice(0, 2).toUpperCase()}`;
      }
    },
    version() {
      return version;
    },
  },
  watch: {
    opened(value) {
      if (this.fullwidth) {
        this.openSidebar = value;
      }
    },
    $route() {
      if (this.fullwidth) {
        this.$emit('close-side-bar');
      }
    },
  },
  created() {
    if (!mediaQuery.matches) {
      this.openSidebar = false;
    }
  },
  mounted() {
    if (!mediaQuery.matches) {
      this.toggleSideBar();
    }

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', this.onMediaQueryChange);
    } else {
      mediaQuery.addListener(this.onMediaQueryChange);
    }
  },
  beforeDestroy() {
    if (mediaQuery.removeEventListener) {
      mediaQuery.removeEventListener('change', this.onMediaQueryChange);
    } else {
      mediaQuery.removeListener(this.onMediaQueryChange);
    }
  },
  methods: {
    belongsToBeta(betaGroups) {
      const groups = betaGroups || [];
      return groups.some((group) => hasGroup(group));
    },
    isAuthorized(nav) {
      return isAuthorized(nav.meta.authorize, false);
    },
    toggleSideBar() {
      this.expand = !this.expand;
      this.reduce = !this.reduce;
      this.fullwidth = !this.fullwidth;
    },
    onMediaQueryChange(event) {
      if (event.matches) {
        this.openSidebar = true;
      } else {
        this.openSidebar = false;
      }

      this.toggleSideBar();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/app';

.sidebar-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 56px;
  width: 100%;
}

.b-sidebar .sidebar-content {
  background-color: #fff !important;
}

.identity {
  display: flex;
  flex-direction: column;
}

.circle {
  border: 1px solid $taster-blue;
  border-radius: 50%;
  color: $taster-blue;
  font-size: 30px;
  height: 75px;
  line-height: 75px;
  margin: auto;
  width: 75px;
}

.menu-list a {
  font-size: 0.9rem;
  padding: inherit;

  &.is-active {
    background-color: #fff !important;
    color: $taster-blue !important;
  }
}

.version {
  font-size: 0.75rem;
}

.email {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media screen and (min-width: $desktop) {
  .b-sidebar .sidebar-content {
    box-shadow: none !important;
    border-right: 0.1px solid #e4e4e4;

    &.is-mini {
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        hr {
          margin: 0.75rem 0;
        }

        .identity {
          .circle {
            font-size: 13px;
            height: 32px;
            line-height: 32px;
            width: 32px;
            margin-top: 20px;
            margin-bottom: 43px;
          }

          .email {
            display: none !important;
          }
        }

        .menu-label {
          hr {
            display: block;
            margin: 0.45rem 0;
          }

          span {
            display: none;
          }
        }

        .menu-list li {
          text-align: center;

          a span:nth-child(2) {
            display: none;
          }
        }

        .version {
          font-size: 0.5rem;
        }
      }

      &.is-mini-expand,
      &.is-mini-expand:hover {
        hr {
          margin: 0.25rem 0;
        }

        .email {
          display: flex !important;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }

        .menu-label {
          hr {
            display: none;
          }

          span {
            display: block;
            font-size: 0.9rem;
          }
        }

        .menu-list a {
          padding: 0.2em 0.75em !important;
        }
      }
    }
  }
}
</style>
