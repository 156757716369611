import Vue from 'vue';

export const roles = {
  Engineers: 'Super Administrator',
  OneSourceAccess: 'One Source Access',
  StockInAdmin: 'Stock In Admin',
  StockInEmitter: 'Stock In Editor',
  StockInViewer: 'Stock In Viewer',
  StockOutAdmin: 'Stock Out Admin',
  StockOutEmitter: 'Stock Out Editor',
  StockOutViewer: 'Stock Out Viewer',
  StockTransferAdmin: 'Stock Transfer Admin',
  StockTransferEmitter: 'Stock Transfer Editor',
  StockTransferViewer: 'Stock Transfer Viewer',
  SupplyAdmin: 'Supply Admin',
  SupplyController: 'Supply Controller',
  SupplyInventoryEditor: 'Supply Inventory Editor',
  SupplyInventoryViewer: 'Supply Inventory Viewer',
  SupplyOrderValidator: 'Supply Order Validator',
  SupplyPurchaseHistoryViewer: 'Supply Purchase History Viewer',
  SupplyTemperatureEditor: 'Supply Temperature Editor',
};

export const groups = {
  BetaIssues: '[Beta][OS] Issues',
};

export const hasRole = function(role) {
  return Vue.prototype.$auth.getUserRoles().includes(roles[role]);
};

export const hasGroup = function(group) {
  if (Vue.prototype.$auth) {
    return Vue.prototype.$auth.userGroups.includes(group);
  }
  return false;
};
