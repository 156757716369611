import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

export const dateFormatter = {
  methods: {
    formatDate: (date) => dayjs(date).format('dddd, MMMM D'),
    formatDateShort: (date) => dayjs(date).format('ddd, MMM D'),
    formatDateFull: (date) => dayjs(date).format('dddd, MMMM D'),
    formatDateFullWithYear: (date) => dayjs(date).format('dddd, MMMM D YYYY'),
    formatTimeShort: (date) => dayjs(date).format('YY-MM-DD HH:mm'),
    formatDateTime: (date) => dayjs(date).format('dddd, MMMM D LT'),
    formatDateTimeWithYear: (date) => dayjs(date).format('dddd, MMMM D YYYY LT'),
    dateDefaultFormat: (date) => dayjs(date).format('YYYY-MM-DD'),
    formatWeekDay: (date) => dayjs(date).format('ddd'),
    formatShortWeekDay: (date) => dayjs(date).format('D MMMM'),
    formatFullWeekDay: (date) => dayjs(date).format('dddd'),
    formatShort: (date) => dayjs(date).format('DD/MM/YYYY'),
  },
};
