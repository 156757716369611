export const toaster = {
    watch: {
        isSaveLoading(value) {
            if (!value) {
                if (!this.saveError) {
                    this.$buefy.toast.open({
                        message: 'Successfully saved',
                        type: 'is-success',
                        position: 'is-bottom',
                    });
                } else {
                    this.$buefy.toast.open({
                        message: 'Saving failed',
                        type: 'is-danger',
                        position: 'is-bottom',
                    });
                }
            }
        },
    },
    methods:{
        openToast(message, type, position){
            if (!position) position = "is-top"
            if (!type) type = "is-success"
            this.$buefy.toast.open({
                message: message,
                type: type,
                position: position
            })
        }
    }
};