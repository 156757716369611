import stockInApi from '@/apis/stock/stockIn';
import stockOutApi from '@/apis/stock/stockOut';
import transferApi from '@/apis/stock/transfer';
import { Event, EventType } from '@/utils/stock/event';
import { eventTypes } from './types';

export default {
  state: {
    stockIns: {
      fetching: false,
      perPage: undefined,
      startAfter: undefined,
      done: false,
      items: [],
    },
    stockOuts: {
      fetching: false,
      perPage: undefined,
      startAfter: undefined,
      done: false,
      items: [],
    },
    transfers: {
      fetching: false,
      perPage: undefined,
      startAfter: undefined,
      done: false,
      items: [],
    },
    fetchingEvents: false,
  },
  mutations: {
    [eventTypes.FETCH_ALL_EVENTS](state, { kind, data, replace = false }) {
      switch (kind) {
        case EventType.StockIn: {
          state.stockIns = {
            ...state.stockIns,
            perPage: data.per_page,
            startAfter: data.start_after,
            done: data.done,
          };

          const items = data.items.map(doc => new Event(EventType.StockIn, doc));
          state.stockIns.items = replace ? items : [...state.stockIns.items, ...items];
          break;
        }
        case EventType.StockOut: {
          state.stockOuts = {
            ...state.stockOuts,
            perPage: data.per_page,
            startAfter: data.start_after,
            done: data.done,
          };

          const items = data.items.map(doc => new Event(EventType.StockOut, doc));
          state.stockOuts.items = replace ? items : [...state.stockOuts.items, ...items];
          break;
        }
        case EventType.Transfer: {
          state.transfers = {
            ...state.transfers,
            perPage: data.per_page,
            startAfter: data.start_after,
            done: data.done,
          };

          const items = data.items.map(doc => new Event(EventType.Transfer, doc));
          state.transfers.items = replace ? items : [...state.transfers.items, ...items];
          break;
        }
      }
    },
    [eventTypes.FETCH_ALL_EVENTS_PENDING](state, { kind, pending }) {
      switch (kind) {
        case EventType.StockIn:
          state.stockIns.fetching = pending;
          break;
        case EventType.StockOut:
          state.stockOuts.fetching = pending;
          break;
        case EventType.Transfer:
          state.transfers.fetching = pending;
          break;
      }
    },
    [eventTypes.RESET_EVENT_LISTS](state, kind) {
      switch (kind) {
        case EventType.StockIn:
          state.stockIns = {
            ...state.stockIns,
            perPage: undefined,
            startAfter: undefined,
            done: false,
            items: [],
          };
          break;
        case EventType.StockOut:
          state.stockOuts = {
            ...state.stockOuts,
            perPage: undefined,
            startAfter: undefined,
            done: false,
            items: [],
          };
          break;
        case EventType.Transfer:
          state.transfers = {
            ...state.transfers,
            perPage: undefined,
            startAfter: undefined,
            done: false,
            items: [],
          };
          break;
      }
    },
  },
  actions: {
    async fetchAllStockIns({ state, commit }, { params = {}, replace = false }) {
      if (state.stockIns.fetching) {
        return;
      }

      try {
        commit(eventTypes.FETCH_ALL_EVENTS_PENDING, { kind: EventType.StockIn, pending: true });
        const res = await stockInApi.fetchAll(params);
        commit(eventTypes.FETCH_ALL_EVENTS, { kind: EventType.StockIn, data: res.data, replace });
      } finally {
        commit(eventTypes.FETCH_ALL_EVENTS_PENDING, { kind: EventType.StockIn, pending: false });
      }
    },
    async fetchAllStockOuts({ state, commit }, { params = {}, replace = false }) {
      if (state.stockOuts.fetching) {
        return;
      }

      try {
        commit(eventTypes.FETCH_ALL_EVENTS_PENDING, { kind: EventType.StockOut, pending: true });
        const res = await stockOutApi.fetchAll(params);
        commit(eventTypes.FETCH_ALL_EVENTS, { kind: EventType.StockOut, data: res.data, replace });
      } finally {
        commit(eventTypes.FETCH_ALL_EVENTS_PENDING, { kind: EventType.StockOut, pending: false });
      }
    },
    async fetchAllTransfers({ state, commit }, { params = {}, replace = false }) {
      if (state.transfers.fetching) {
        return;
      }

      try {
        commit(eventTypes.FETCH_ALL_EVENTS_PENDING, { kind: EventType.Transfer, pending: true });
        const res = await transferApi.fetchAll(params);
        commit(eventTypes.FETCH_ALL_EVENTS, { kind: EventType.Transfer, data: res.data, replace });
      } finally {
        commit(eventTypes.FETCH_ALL_EVENTS_PENDING, { kind: EventType.Transfer, pending: false });
      }
    },
    async fetchTransfer(_, { uuid, raw = false }) {
      const res = await transferApi.get(uuid);
      if (raw) {
        return res.data;
      }

      return new Event(EventType.Transfer, res.data);
    },
    async getStockInReceiptUrl(_, { uuid, expiration = undefined }) {
      const res = await stockInApi.getReceipt(uuid, expiration);
      return res.data;
    },
    async fetchAwaitingDeliveryTransfers(_, { location, deliveryDate }) {
      const res = await transferApi.fetchAll({
        deliveryDate,
        deliveryStatus: 'AWAITING_DELIVERY',
        destinationUuid: location,
      });
      return res.data.items.map(doc => new Event(EventType.Transfer, doc));
    },
    async fetchDeliveredTransfers(_, { location, deliveryDate }) {
      const res = await transferApi.fetchAll({
        deliveryDate,
        deliveryStatus: 'DELIVERED',
        destinationUuid: location,
      });
      return res.data.items.map(doc => new Event(EventType.Transfer, doc));
    },
    resetEventsList({ commit }, kind) {
      commit(eventTypes.RESET_EVENT_LISTS, kind);
    },
    createStockIn(_, payload) {
      return stockInApi.create(payload);
    },
    createStockOut(_, payload) {
      return stockOutApi.create(payload);
    },
    createTransfer(_, payload) {
      return transferApi.create(payload);
    },
    updateStockIn(_, { uuid, payload }) {
      return stockInApi.update(uuid, payload);
    },
    uploadStockInReceipt(_, { uuid, receipt }) {
      return stockInApi.uploadReceipt(uuid, receipt);
    },
    updateStockOut(_, { uuid, payload }) {
      return stockOutApi.update(uuid, payload);
    },
    updateTransfer(_, { uuid, payload }) {
      return transferApi.update(uuid, payload);
    },
    updateTransferProduct(_, { uuid, productUuid, payload }) {
      return transferApi.updateProduct(uuid, productUuid, payload);
    },
    updateTransferProducts(_, { uuid, products }) {
      return transferApi.updateProducts(uuid, products);
    },
    uploadTransferNote(_, { uuid, kitchenId, deliverLabel, note }) {
      return transferApi.uploadNote(uuid, kitchenId, deliverLabel, note);
    },
    changeTransferStatus(_, { uuid, from, to }) {
      switch (`${from}:${to}`) {
        case 'AWAITING_DELIVERY:DELIVERED':
          return transferApi.passToDelivered(uuid);
      }

      throw new Error(`Cannot pass Transfer event from ${from} to ${to} state`);
    },
    deleteStockIn(_, uuid) {
      return stockInApi.delete(uuid);
    },
    deleteStockOut(_, uuid) {
      return stockOutApi.delete(uuid);
    },
    deleteTransfer(_, uuid) {
      return transferApi.delete(uuid);
    },
  },
  getters: {
    canFetchMoreEvents: state => kind => {
      switch (kind) {
        case EventType.StockIn:
          return !(state.stockIns.done || false);
        case EventType.StockOut:
          return !(state.stockOuts.done || false);
        case EventType.Transfer:
          return !(state.transfers.done || false);
      }

      return undefined;
    },
    getEventListLength: state => kind => {
      switch (kind) {
        case EventType.StockIn:
          return state.stockIns.items.length;
        case EventType.StockOut:
          return state.stockOuts.items.length;
        case EventType.Transfer:
          return state.transfers.items.length;
      }
    },
    getEvents: state => (kind, page = undefined) => {
      switch (kind) {
        case EventType.StockIn: {
          const perPage = state.stockIns.perPage;
          return page === undefined
            ? state.stockIns.items
            : state.stockIns.items.slice((page - 1) * perPage, page * perPage);
        }
        case EventType.StockOut: {
          const perPage = state.stockOuts.perPage;
          return page === undefined
            ? state.stockOuts.items
            : state.stockOuts.items.slice((page - 1) * perPage, page * perPage);
        }
        case EventType.Transfer: {
          const perPage = state.transfers.perPage;
          return page === undefined
            ? state.transfers.items
            : state.transfers.items.slice((page - 1) * perPage, page * perPage);
        }
      }

      return [];
    },
    startAfter: state => kind => {
      switch (kind) {
        case EventType.StockIn:
          return state.stockIns.startAfter;
        case EventType.StockOut:
          return state.stockOuts.startAfter;
        case EventType.Transfer:
          return state.transfers.startAfter;
      }

      return undefined;
    },
  },
};
