export const purchaseOrdersTypes = {
  FETCH_ALL_PURCHASES: 'FETCH_ALL_PURCHASES',
  FETCH_ALL_PURCHASES_PENDING: 'FETCH_ALL_PURCHASES_PENDING',
  FETCH_ALL_DELIVERS: 'FETCH_ALL_DELIVERS',
  FETCH_ALL_DELIVERS_PENDING: 'FETCH_ALL_DELIVERS_PENDING',
  FETCH_ONE_PURCHASE: 'FETCH_ONE_PURCHASE',
  FETCH_ONE_PURCHASE_PENDING: 'FETCH_ONE_PURCHASE_PENDING',
  FETCH_ONE_PURCHASE_TASKS: 'FETCH_ONE_PURCHASE_TASKS',
  FETCH_ONE_PURCHASE_TASKS_PENDING: 'FETCH_ONE_PURCHASE_TASKS_PENDING',
  UNSET_CURRENT_PURCHASE: 'UNSET_CURRENT_PURCHASE',
  FETCH_ALL_DRAFTED_ORDERS: 'FETCH_ALL_DRAFTED_ORDERS',
  FETCH_ALL_DRAFTED_ORDERS_PENDING: 'FETCH_ALL_DRAFTED_ORDERS_PENDING',
  FETCH_ALL_SUGGESTED_ORDERS: 'FETCH_ALL_SUGGESTED_ORDERS',
  FETCH_ALL_SUGGESTED_ORDERS_PENDING: 'FETCH_ALL_SUGGESTED_ORDERS_PENDING',
  FETCH_ALL_AWAITING_VALIDATION_ORDERS: 'FETCH_ALL_AWAITING_VALIDATION_ORDERS',
  FETCH_ALL_AWAITING_VALIDATION_ORDERS_PENDING: 'FETCH_ALL_AWAITING_VALIDATION_ORDERS_PENDING',
  FETCH_ALL_VALIDATED_ORDERS: 'FETCH_ALL_VALIDATED_ORDERS',
  FETCH_ALL_VALIDATED_ORDERS_PENDING: 'FETCH_ALL_VALIDATED_ORDERS_PENDING',
  FETCH_ALL_AWAITING_DELIVERY_ORDERS: 'FETCH_ALL_AWAITING_DELIVERY_ORDERS',
  FETCH_ALL_AWAITING_DELIVERY_ORDERS_PENDING: 'FETCH_ALL_AWAITING_DELIVERY_ORDERS_PENDING',
  FETCH_ALL_DELIVERED_ORDERS: 'FETCH_ALL_DELIVERED_ORDERS',
  FETCH_ALL_DELIVERED_ORDERS_PENDING: 'FETCH_ALL_DELIVERED_ORDERS_PENDING',
  FETCH_MORE: 'FETCH_MORE',
  FETCH_NUMBER_OF_ORDERS_TO_VALIDATE: 'FETCH_NUMBER_OF_ORDERS_TO_VALIDATE',
  RESET_ORDERS_LIST: 'RESET_ORDERS_LIST',
  GET_HISTORY: 'GET_HISTORY',
  GET_HISTORY_PENDING: 'GET_HISTORY_PENDING',
  SAVE_AWAITING_VALIDATION_QUANTITY: 'SAVE_AWAITING_VALIDATION_QUANTITY',
  REMOVE_AWAITING_VALIDATION_QUANTITY: 'REMOVE_AWAITING_VALIDATION_QUANTITY',
  VALIDATED_GENERATE_PDF: 'VALIDATED_GENERATE_PDF',
};

export const inventoriesTypes = {
  FETCH_ALL_INVENTORIES: 'FETCH_ALL_INVENTORIES',
  FETCH_ALL_INVENTORIES_PENDING: 'FETCH_ALL_INVENTORIES_PENDING',
  FETCH_ONE_INVENTORY: 'FETCH_ONE_INVENTORY',
  FETCH_ONE_INVENTORY_PENDING: 'FETCH_ONE_INVENTORY_PENDING',
  UNSET_CURRENT_INVENTORY: 'UNSET_CURRENT_INVENTORY',
  GENERATE_INVENTORY_PRODUCTS_PENDING: 'GENERATE_INVENTORY_PRODUCTS_PENDING',
};

export const temperatureControlsTypes = {
  FETCH_ALL_TEMPERATURES: 'FETCH_ALL_TEMPERATURES',
  FETCH_ALL_TEMPERATURES_PENDING: 'FETCH_ALL_TEMPERATURES_PENDING',
};

export const schedulesTypes = {
  FETCH_ALL_EVENTS: 'FETCH_ALL_EVENTS',
  FETCH_ALL_EVENTS_PENDING: 'FETCH_ALL_EVENTS_PENDING',
  GET_ONE_EVENT_PENDING: 'GET_ONE_EVENT_PENDING',
  VALIDATE_EVENT_PENDING: 'VALIDATE_EVENT_PENDING',
  CREATE_EVENT_PENDING: 'CREATE_EVENT_PENDING',
  EDIT_EVENT_PENDING: 'EDIT_EVENT_PENDING',
  SKIP_EVENT_DAY_PENDING: 'SKIP_EVENT_DAY_PENDING',
  DELETE_EVENT_PENDING: 'DELETE_EVENT_PENDING',
};
