import { StockOutEventApi } from '@tasterkitchens/stock-manager-client';
import Vue from 'vue';

const api = new StockOutEventApi({
  basePath: process.env.VUE_APP_STOCK_MANAGER_ENDPOINT,
  accessToken: () => Vue.prototype.$auth.getToken(),
});

export default {
  /**
   * Returns an iterator over a Stock Out events collection.
   * @async
   * @param {Object} params API request parameters
   * @param {String|undefined} params.reason A unique identifier for a reason.
   * @param {String|undefined} params.locationUuid A specific location UUID.
   * @param {String|undefined} params.orderBy A field we want to use to order the collection.
   * @param {Boolean} params.desc Does the collection needs to be reversed?
   * @param {String|undefined} params.startAfter The iterator should start after the provided UUID.
   * @param {Number} params.perPage The number of event per page.
   * @param {Boolean|undefined} params.withDeleted Should we retrieve soft deleted event too?
   */
   fetchAll({
    reason = undefined,
    locationUuid = undefined,
    orderBy = undefined,
    desc = false,
    startAfter = undefined,
    perPage = 20,
    withDeleted = undefined,
  } = {}) {
    return api.listStockOut(
      reason,
      locationUuid,
      orderBy,
      desc,
      startAfter,
      perPage,
      withDeleted,
    );
  },
  /**
   * Creates a new Stock Out event in the manager.
   * @async
   * @param {*} payload The payload representing the new Stock Out event.
   */
  create(payload) {
    return api.createStockOut(payload);
  },
  /**
   * Updates an existing Stock Out event in the manager.
   * @async
   * @param {String} uuid The UUID of the event to update.
   * @param {*} payload The payload of the update.
   */
  update(uuid, payload) {
    return api.updateStockOut(uuid, payload);
  },
  /**
   * Soft deletes a Stock Out event in the manager.
   * @async
   * @param {String} uuid UUID of the Stock Out event to delete.
   */
  delete(uuid) {
    return api.deleteStockOut(uuid);
  },
};
