import Vue from 'vue';
import { Configuration, ProvidersApi, ClaimsApi } from '@tasterkitchens/client-ts-gateway-supply-v1';

const gatewaySupplyV1Clients = () => {
  const token = `Bearer ${Vue.prototype.$auth.getToken()}`;
  const apiConfiguration = new Configuration({
    accessToken: token,
    basePath: process.env.VUE_APP_GATEWAY_SUPPLY_V1_ENDPOINT,
  });
  const providersApi = new ProvidersApi(apiConfiguration);
  const claimsApi = new ClaimsApi(apiConfiguration);

  return { providersApi, claimsApi };
};

const listProviderProducts = (requestParams) => {
  const { providersApi } = gatewaySupplyV1Clients();

  return providersApi.listProviderProducts(requestParams);
};

const createClaim = (requestParams) => {
  const { claimsApi } = gatewaySupplyV1Clients();

  return claimsApi.createClaim(requestParams);
};

export { listProviderProducts, createClaim };
