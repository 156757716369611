import { listProviderProducts, createClaim } from '@/apis/gatewaySupplyV1';
import types from './types';
import { keyBy } from 'lodash';

export default {
  namespaced: true,
  state: {
    products: [],
    fetchProviderProducts: {
      pending: false,
    },
  },
  mutations: {
    [types.FETCH_PROVIDER_PRODUCTS_PENDING](state, pending) {
      state.fetchProviderProducts.pending = pending;
    },
    [types.FETCH_PROVIDER_PRODUCTS](state, products) {
      state.products = products;
    },
  },
  actions: {
    async fetchProviderProducts({ commit }, requestParams) {
      try {
        commit(types.FETCH_PROVIDER_PRODUCTS_PENDING, true);
        const response = await listProviderProducts(requestParams);
        commit(types.FETCH_PROVIDER_PRODUCTS, response.items);
        return response.items;
      } finally {
        commit(types.FETCH_PROVIDER_PRODUCTS_PENDING, false);
      }
    },
    sendClaim(_, requestParams) {
      return createClaim(requestParams);
    },
  },
  getters: {
    getProductByUuid: (state) => (uuid) => {
      return state.products.find((_) => _.uuid === uuid);
    },
    productsKeyByUuid: (state) => {
      return keyBy(state.products, 'uuid');
    },
  },
};
