var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"sidebar-page"},[_c('b-sidebar',{attrs:{"can-cancel":false,"expand-on-hover":_vm.expand,"fullwidth":_vm.fullwidth,"open":!_vm.reduce ? _vm.opened : true,"reduce":_vm.reduce,"type":"is-light","fullheight":""}},[_c('div',{staticClass:"sidebar-container"},[_c('div',{staticClass:"identity mt-4 has-text-centered"},[_c('span',{staticClass:"circle"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('span',{staticClass:"email is-size-7 my-4 is-hidden-desktop"},[_vm._v(" "+_vm._s(_vm.email)+" "),_c('b-button',{attrs:{"size":"is-small","icon-right":"arrow-collapse-right"},on:{"click":_vm.$auth.logout}},[_vm._v(" logout ")])],1)]),_c('hr',{staticClass:"solid is-hidden-touch"}),(!_vm.isDeprecatedUser)?_c('b-menu',[_c('b-menu-list',[_c('b-menu-item',{attrs:{"active":_vm.$route.name === 'home',"icon":"home","label":_vm.$t('menu.home'),"tag":"router-link","to":{ name: 'home' }}})],1)],1):_vm._e(),(!_vm.isDeprecatedUser)?_c('div',[_vm._l((_vm.navigation),function(nav){return [(
              nav.menu &&
                nav.display &&
                _vm.isAuthorized(nav) &&
                ((nav.betaGroups || []).length === 0 || _vm.belongsToBeta(nav.betaGroups))
            )?_c('b-menu-list',{key:nav.name,scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('hr',{staticClass:"solid is-hidden-touch"}),_c('span',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.$t(("menu." + (nav.keyName))))+" ")])]},proxy:true}],null,true)},[_vm._l((nav.children),function(child){return [(
                  child.display &&
                    _vm.isAuthorized(child) &&
                    ((child.betaGroups || []).length === 0 || _vm.belongsToBeta(child.betaGroups))
                )?_c('SideBarItem',{key:child.name,attrs:{"route":child},scopedSlots:_vm._u([(child.name === 'validation')?{key:"decorator",fn:function(){return [(_vm.numberAwaitingOrders)?_c('b-tag',{staticClass:"is-pulled-right",attrs:{"type":"is-taster-blue","rounded":""}},[_vm._v(" "+_vm._s(_vm.numberAwaitingOrders)+" ")]):_vm._e()]},proxy:true}:null],null,true)}):_vm._e()]})],2):_vm._e()]})],2):_vm._e(),_c('p',{staticClass:"version mt-4 has-text-centered"},[_vm._v(" Version: "),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.version))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }