import { LocationApi } from '@tasterkitchens/stock-manager-client';
import Vue from 'vue';

const api = new LocationApi({
  basePath: process.env.VUE_APP_STOCK_MANAGER_ENDPOINT,
  accessToken: () => Vue.prototype.$auth.getToken(),
});

export default {
  /**
   * Returns all the stock item objects. available to a location.
   * @async
   * @param {String} locationUuid The UUID of the location where to retrieve objects.
   */
  fetchStockItems(locationUuid) {
    return api.listLocationStockItems(locationUuid);
  },
  /**
   * Returns all the stock menu item objects available to a location.
   * @async
   * @param {String} locationUuid The UUID of the location where to retrieve objects.
   */
  fetchStockMenuItems(locationUuid) {
    return api.listLocationStockMenuItems(locationUuid);
  },
  /**
   * Returns all the stock object available to a location.
   * @async
   * @param {String} locationUuid The UUID of the location where to retrieve objects.
   */
  fetchStockObjects(locationUuid) {
    return api.listLocationStockObjects(locationUuid);
  },
  /**
   * Returns all the stock product objects available to a location.
   * @async
   * @param {String} locationUuid The UUID of the location where to retrieve objects.
   */
  fetchStockProducts(locationUuid) {
    return api.listLocationStockProducts(locationUuid);
  }
};
