import { mapState, mapActions } from 'vuex';

export const inventoriesMgr = {
  computed: {
    ...mapState({
      beforeShift: (state) => state.purchaseOrders.beforeShift,
      inventories: (state) => state.purchaseOrders.inventories.items,
      inventoriesTotal: (state) => state.purchaseOrders.inventories.total,
      currentInventory: (state) => state.purchaseOrders.currentInventory,
      fetchAllInventoriesLoading: (state) => state.purchaseOrders.fetchAllInventories.pending,
      fetchOneInventoryLoading: (state) => state.purchaseOrders.fetchOneInventory.pending,
      generateInventoryProductsLoading: (state) => state.purchaseOrders.generateInventoryProducts.pending,
    }),
  },
  methods: {
    ...mapActions('purchaseOrders', {
      addProductToInventory: 'addProductToInventory',
      fetchAllInventories: 'fetchAllInventories',
      fetchOneInventory: 'fetchOneInventory',
      unsetCurrentInventory: 'unsetCurrentInventory',
      updateProductQuantity: 'updateProductQuantity',
      createOneInventory: 'createOneInventory',
      generateInventoryProducts: 'generateInventoryProducts',
      createInventoryProduct: 'createInventoryProduct',
      deleteOneInventory: 'deleteOneInventory',
      updateProductsQuantities: 'updateProductsQuantities',
      updateOneInventory: 'updateOneInventory',
    }),
    ...mapActions(['setError', 'processError']),
    async fetchInventories(opts) {
      if (!this.fetchAllInventoriesLoading) {
        try {
          await this.fetchAllInventories(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchInventory(uuid) {
      if (!this.fetchOneInventoryLoading) {
        try {
          await this.fetchOneInventory({ uuid: uuid });
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
  },
};
