import Vue from 'vue';

// TODO: removes this snippet if https://github.com/OpenAPITools/openapi-generator/issues/9523
// is addressed or leave it until we've changed the generator template.
const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');
const GatewaySupplyClient = require('@tasterkitchens/gateway-supply-client');

const parseDate = function(str) {
  return new Date(isNaN(str) ? str : +str);
};

PurchaseOrdersClient.ApiClient.parseDate = parseDate;
GatewaySupplyClient.ApiClient.parseDate = parseDate;

export const clientHook = function(client) {
  // To avoir Refused to set unsafe header "User-Agent" error from browser
  client.apiClient.defaultHeaders = {};
  client.apiClient.timeout = 120000;

  if (client.apiClient.authentications.oauth2) {
    client.apiClient.authentications.oauth2['accessToken'] = Vue.prototype.$auth.getToken();
  }

  if (client.apiClient.authentications.OAuth2) {
    client.apiClient.authentications.OAuth2['accessToken'] = Vue.prototype.$auth.getToken();
  }

  return client;
};

export default clientHook;
