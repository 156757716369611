import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import fr from 'vee-validate/dist/locale/fr.json';
import nl from 'vee-validate/dist/locale/nl.json';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  messages.en = {
    ...messages.en,
    vee: {
      ...messages.en.vee,
      ...en.messages,
    },
  };
  messages.es = {
    ...messages.es,
    vee: {
      ...messages.es.vee,
      ...es.messages,
    },
  };
  messages.fr = {
    ...messages.fr,
    vee: {
      ...messages.fr.vee,
      ...fr.messages,
    },
  };
  messages.nl = {
    ...messages.nl,
    vee: {
      ...messages.nl.vee,
      ...nl.messages,
    },
  };

  return messages;
}

export default new VueI18n({
  locale:
    localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || navigator.userLanguage || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
