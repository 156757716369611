<template>
  <div class="profile">
    <div class="columns is-centered has-text-centered">
      <div class="column">
        <div class="mt-4 has-text-centered">
          <div class="circle-profile">{{ name }}</div>
          <div class="is-size-4 my-4">{{ $t('profile.welcome') }} {{ firstName }} {{ familyName }} !</div>
        </div>
      </div>
    </div>
    <div v-if="currentLocation">
      <div class="title">{{ $t('rights') }}</div>
      <div class="box box-tiled">
        <b-table paginated pagination-size="is-small" :data="authorizedLocationsWithLevel">
          <b-table-column field="id" v-slot="props" :label="$t('kitchen')">{{ props.row.id }}</b-table-column>
          <b-table-column v-slot="props" label="Viewer" centered>
            <span :class="getClass(props.row, 'viewers')"></span>
          </b-table-column>
          <b-table-column v-slot="props" label="Editor" centered>
            <span :class="getClass(props.row, 'editors')"></span>
          </b-table-column>
          <b-table-column v-slot="props" label="Admin" centered>
            <span :class="getClass(props.row, 'admins')"></span>
          </b-table-column>
        </b-table>
      </div>
      <div class="title">{{ $t('menu.settings') }}</div>
      <div class="mt-3 box box-tiled">
        <hr class="solid my-1" />
        <div>
          <nav class="level">
            <div class="level-left">
              <span class="subtitle is-vcentered">Cache</span>
            </div>
            <div class="level-right">
              <b-button icon-right="delete" @click="clearCache">{{ $t('clearCache') }}</b-button>
            </div>
          </nav>
        </div>
        <hr class="solid my-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { authHelpers, restaurantsMgr, errorHandler } from '@/mixins';
export default {
  mixins: [authHelpers, restaurantsMgr, errorHandler],
  computed: {
    firstName() {
      return this.$auth.user ? this.$auth.user.given_name : '';
    },
    familyName() {
      return this.$auth.user ? this.$auth.user.family_name : '';
    },
    name() {
      if (this.$auth.user) {
        let firstChar = this.firstName.length ? this.firstName.charAt(0) : '';
        switch (this.familyName.length) {
          case 0:
            return firstChar.toUpperCase() + '';
          case 1:
            return firstChar.toUpperCase() + this.familyName.toUpperCase();
          default:
            return (
              firstChar.toUpperCase() +
              this.familyName.charAt(0).toUpperCase() +
              this.familyName.charAt(1).toUpperCase()
            );
        }
      }
      return '';
    },
    email() {
      if (this.$auth.user) return this.$auth.user.email;
      return '';
    },
  },
  methods: {
    getClass(row, right) {
      return ['dot', { 'dot-failure': !row.levels.includes(right) }, { 'dot-success': row.levels.includes(right) }];
    },
    clearCache() {
      window.localStorage.clear();
      this.$router.go();
    },
  },
  async created() {
    await this.fetchLocations();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app';
.circle-profile {
  margin: auto;
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  font-size: 60px;
  color: $taster-blue;
  text-align: center;
  border: 3px solid $taster-blue;
}
.box-tiled {
  border-radius: 0 !important;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.dot-failure {
  background-color: $taster-blue;
}
.dot-success {
  background-color: $success;
}
</style>
