import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const schedulesApi = new PurchaseOrdersClient.DeliverSchedulesApi();
schedulesApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  async fetchAllEvents(locationUuids, startDate, endDate) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesListEvents({
      location_uuids: locationUuids,
      start_date: startDate,
      end_date: endDate,
    });
  },
  async getEvent(deliveryDate, scheduleUuid) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesGetEvent({
      delivery_date: deliveryDate,
      schedule_uuid: scheduleUuid,
    });
  },
  async validateEvent(
    deliverUuid,
    deliverType,
    locations,
    deliveryDate,
    orderingDate,
    draftDate,
    coverageStartDate,
    coverageEndDate,
    recurrenceFrequency,
    recurrenceStart,
    recurrenceEnd,
    orderingHour,
    deliveryStartHour,
    deliveryEndHour,
    skip_days,
    mode,
  ) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesValidateEvent({
      coverage_end_date: coverageStartDate,
      coverage_start_date: coverageEndDate,
      deliver_type: deliverType,
      deliver_uuid: deliverUuid,
      delivery_date: deliveryDate,
      delivery_end_hour: deliveryEndHour,
      delivery_start_hour: deliveryStartHour,
      draft_date: draftDate,
      locations: locations,
      ordering_date: orderingDate,
      ordering_hour: orderingHour,
      recurrence_end: recurrenceEnd,
      recurrence_frequency: recurrenceFrequency,
      recurrence_start: recurrenceStart,
      skip_days: skip_days,
      mode: mode,
    });
  },
  async createEvent(
    deliverUuid,
    deliverType,
    locations,
    deliveryDate,
    orderingDate,
    draftDate,
    coverageStartDate,
    coverageEndDate,
    recurrenceFrequency,
    recurrenceStart,
    recurrenceEnd,
    orderingHour,
    deliveryStartHour,
    deliveryEndHour,
    skip_days,
  ) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesCreateEvent({
      coverage_start_date: coverageStartDate,
      coverage_end_date: coverageEndDate,
      deliver_type: deliverType,
      deliver_uuid: deliverUuid,
      delivery_date: deliveryDate,
      delivery_end_hour: deliveryEndHour,
      delivery_start_hour: deliveryStartHour,
      draft_date: draftDate,
      locations: locations,
      ordering_date: orderingDate,
      ordering_hour: orderingHour,
      recurrence_end: recurrenceEnd,
      recurrence_frequency: recurrenceFrequency,
      recurrence_start: recurrenceStart,
      skip_days: skip_days,
    });
  },
  async editEvent(
    deliverUuid,
    deliverType,
    scheduleUuids,
    deliveryDate,
    orderingDate,
    draftDate,
    coverageStartDate,
    coverageEndDate,
    recurrenceFrequency,
    recurrenceEnd,
    orderingHour,
    deliveryStartHour,
    deliveryEndHour,
    skipDays,
    currentDeliveryDate,
  ) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesUpdateEvent({
      coverage_start_date: coverageStartDate,
      coverage_end_date: coverageEndDate,
      deliver_type: deliverType,
      deliver_uuid: deliverUuid,
      delivery_date: deliveryDate,
      delivery_end_hour: deliveryEndHour,
      delivery_start_hour: deliveryStartHour,
      draft_date: draftDate,
      schedule_uuids: scheduleUuids,
      ordering_date: orderingDate,
      ordering_hour: orderingHour,
      recurrence_end: recurrenceEnd,
      recurrence_frequency: recurrenceFrequency,
      skip_days: skipDays,
      current_delivery_date: currentDeliveryDate,
    });
  },
  async skipEventDate(date, scheduleUuids) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesSkipDay({
      date: date,
      schedule_uuids: scheduleUuids,
    });
  },
  async deleteEvent(endDate, scheduleUuids) {
    return await clientHook(schedulesApi).appApisV1RecurrentSchedulesDeleteEvent({
      end_date: endDate,
      schedule_uuids: scheduleUuids,
    });
  },
};
