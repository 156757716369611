export const locationsTypes = {
    FETCH_ALL: "FETCH_ALL",
    FETCH_NESTED: 'FETCH_NESTED',
    FETCH_ONE: 'FETCH_ONE',
    FETCH_ALL_PENDING: "FETCH_ALL_PENDING",
    FETCH_NESTED_PENDING: 'FETCH_NESTED_PENDING',
    FETCH_ONE_PENDING: 'FETCH_ONE_PENDING',
    SET_CURRENT: 'SET_CURRENT',
};

export const kitchensTypes = {
    FETCH_ONE_KITCHEN: 'FETCH_ONE_KITCHEN'
}
