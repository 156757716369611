export const eventTypes = {
  FETCH_ALL_EVENTS: 'FETCH_ALL_EVENTS',
  FETCH_ALL_EVENTS_PENDING: 'FETCH_ALL_EVENTS_PENDING',
  RESET_EVENT_LISTS: 'RESET_EVENT_LISTS',
};

export const objectTypes = {
  FETCH_ALL_OBJECTS: 'FETCH_ALL_OBJECTS',
  FETCH_ALL_OBJECTS_PENDING: 'FETCH_ALL_OBJECTS_PENDING',
};
