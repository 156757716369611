import schedulesApi from '@/apis/purchaseOrders/schedules';
import { schedulesTypes } from './purchaseOrdersTypes';

export default {
  state: {
    events: {},
    fetchEvents: {
      pending: false,
    },
    getOneEvent: {
      pending: false,
    },
    validateEvent: {
      pending: false,
    },
    createEvent: {
      pending: false,
    },
    editEvent: {
      pending: false,
    },
    skipDay: {
      pending: false,
    },
    deleteEvent: {
      pending: false,
    },
  },
  mutations: {
    [schedulesTypes.FETCH_ALL_EVENTS](state, events) {
      state.events = events;
    },
    [schedulesTypes.FETCH_ALL_EVENTS_PENDING]: (state, pending) => (state.fetchEvents.pending = pending),
    [schedulesTypes.GET_ONE_EVENT_PENDING]: (state, pending) => (state.getOneEvent.pending = pending),
    [schedulesTypes.VALIDATE_EVENT_PENDING]: (state, pending) => (state.validateEvent.pending = pending),
    [schedulesTypes.CREATE_EVENT_PENDING]: (state, pending) => (state.createEvent.pending = pending),
    [schedulesTypes.EDIT_EVENT_PENDING]: (state, pending) => (state.editEvent.pending = pending),
    [schedulesTypes.SKIP_EVENT_DAY_PENDING]: (state, pending) => (state.skipDay.pending = pending),
    [schedulesTypes.DELETE_EVENT_PENDING]: (state, pending) => (state.deleteEvent.pending = pending),
  },
  actions: {
    async fetchAllEvents({ commit }, opts) {
      try {
        commit(schedulesTypes.FETCH_ALL_EVENTS_PENDING, true);
        let events = await schedulesApi.fetchAllEvents(opts.locationUuids, opts.startDate, opts.endDate);
        commit(schedulesTypes.FETCH_ALL_EVENTS, events);
        return events;
      } finally {
        commit(schedulesTypes.FETCH_ALL_EVENTS_PENDING, false);
      }
    },
    async getEvent({ commit }, payload) {
      try {
        commit(schedulesTypes.GET_ONE_EVENT_PENDING, true);
        let event = await schedulesApi.getEvent(payload.deliveryDate, payload.scheduleUuid);
        return event;
      } finally {
        commit(schedulesTypes.GET_ONE_EVENT_PENDING, false);
      }
    },
    async validateEvent({ commit }, payload) {
      try {
        commit(schedulesTypes.VALIDATE_EVENT_PENDING, true);
        let conflicts = await schedulesApi.validateEvent(
          payload.deliverUuid,
          payload.deliverType,
          payload.locations,
          payload.deliveryDate,
          payload.orderingDate,
          payload.draftDate,
          payload.coverageStartDate,
          payload.coverageEndDate,
          payload.recurrenceFrequency,
          payload.recurrenceStart,
          payload.recurrenceEnd,
          payload.orderingHour,
          payload.deliveryStartHour,
          payload.deliveryEndHour,
          payload.skipDays,
          payload.mode,
        );
        return conflicts;
      } finally {
        commit(schedulesTypes.VALIDATE_EVENT_PENDING, false);
      }
    },
    async createEvent({ commit }, payload) {
      try {
        commit(schedulesTypes.CREATE_EVENT_PENDING, true);
        let conflicts = await schedulesApi.createEvent(
          payload.deliverUuid,
          payload.deliverType,
          payload.locations,
          payload.deliveryDate,
          payload.orderingDate,
          payload.draftDate,
          payload.coverageStartDate,
          payload.coverageEndDate,
          payload.recurrenceFrequency,
          payload.recurrenceStart,
          payload.recurrenceEnd,
          payload.orderingHour,
          payload.deliveryStartHour,
          payload.deliveryEndHour,
          payload.skipDays,
        );
        return conflicts;
      } finally {
        commit(schedulesTypes.CREATE_EVENT_PENDING, false);
      }
    },
    async editEvent({ commit }, payload) {
      try {
        commit(schedulesTypes.EDIT_EVENT_PENDING, true);
        let event = await schedulesApi.editEvent(
          payload.deliverUuid,
          payload.deliverType,
          payload.scheduleUuids,
          payload.deliveryDate,
          payload.orderingDate,
          payload.draftDate,
          payload.coverageStartDate,
          payload.coverageEndDate,
          payload.recurrenceFrequency,
          payload.recurrenceEnd,
          payload.orderingHour,
          payload.deliveryStartHour,
          payload.deliveryEndHour,
          payload.skipDays,
          payload.currentDeliveryDate,
        );
        return event;
      } finally {
        commit(schedulesTypes.EDIT_EVENT_PENDING, false);
      }
    },
    async skipDay({ commit }, payload) {
      try {
        commit(schedulesTypes.SKIP_EVENT_DAY_PENDING, true);
        let event = await schedulesApi.skipEventDate(payload.date, payload.scheduleUuids);
        return event;
      } finally {
        commit(schedulesTypes.SKIP_EVENT_DAY_PENDING, false);
      }
    },
    async deleteEvent({ commit }, payload) {
      try {
        commit(schedulesTypes.DELETE_EVENT_PENDING, true);
        let event = await schedulesApi.deleteEvent(payload.endDate, payload.scheduleUuids);
        return event;
      } finally {
        commit(schedulesTypes.DELETE_EVENT_PENDING, false);
      }
    },
  },
};
