import { StockInEventApi } from '@tasterkitchens/stock-manager-client';
import Vue from 'vue';

const api = new StockInEventApi({
  basePath: process.env.VUE_APP_STOCK_MANAGER_ENDPOINT,
  accessToken: () => Vue.prototype.$auth.getToken(),
});

export default {
  /**
   * Returns an iterator over a Stock In events collection.
   * @async
   * @param {Object} params API request parameters
   * @param {String|undefined} params.reason A unique identifier for a reason.
   * @param {String|undefined} params.locationUuid A specific location UUID.
   * @param {String|undefined} params.orderBy A field we want to use to order the collection.
   * @param {Boolean} params.desc Does the collection needs to be reversed?
   * @param {String|undefined} params.startAfter The iterator should start after the provided UUID.
   * @param {Number} params.perPage The number of event per page.
   * @param {Boolean|undefined} params.withDeleted Should we retrieve soft deleted event too?
   */
   fetchAll({
    reason = undefined,
    locationUuid = undefined,
    orderBy = undefined,
    desc = false,
    startAfter = undefined,
    perPage = 20,
    withDeleted = undefined,
  } = {}) {
    return api.listStockIn(
      reason,
      locationUuid,
      orderBy,
      desc,
      startAfter,
      perPage,
      withDeleted,
    );
  },
  /**
   * Retrieves the signed URL to the given Stock In event's receipt if it exits.
   * @async
   * @param {String} uuid The Stock In event's UUID.
   * @param {Number|undefined} expiration An optional expiration duration
   */
  getReceipt(uuid, expiration = undefined) {
    return api.getStockInReceipt(uuid, expiration);
  },
  /**
   * Creates a new Stock In event in the manager.
   * @async
   * @param {*} payload The payload representing the new Stock In event.
   */
  create(payload) {
    return api.createStockIn(payload);
  },
  /**
   * Updates an existing Stock In event in the manager.
   * @async
   * @param {String} uuid The UUID of the event to update.
   * @param {*} payload The payload of the update.
   */
  update(uuid, payload) {
    return api.updateStockIn(uuid, payload);
  },
  /**
   * Uploads a receipt on the manager and associates with an existing Stock In event.
   * @async
   * @param {String} uuid The UUID of the event which will receives the receipt.
   * @param {Blob} note The blob representing the receipt to be upload.
   */
  uploadReceipt(uuid, note) {
    return api.uploadStockInNote(uuid, note);
  },
  /**
   * Soft deletes a Stock In event in the manager.
   * @async
   * @param {String} uuid UUID of the Stock In event to delete.
   */
  delete(uuid) {
    return api.deleteStockIn(uuid);
  },
};
