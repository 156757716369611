<template>
  <b-navbar type="is-taster-blue" fixed-top class="nav-bar" :mobile-burger="false" :shadow="false">
    <template slot="brand" class="nav-bar-brand">
      <b-navbar-item tag="div" class="py-0 my-0">
        <div class="icon-burger">
          <b-icon class="my-0 py-0 mx-0 my-0 is-hidden-desktop" icon="menu" @click.native="() => onToggleMenu()" />
        </div>
        <router-link :to="isDeprecatedUser ? '/my-kitchen/reception-control' : '/'">
          <img class="ml-3" src="@/assets/images/one-source.svg" alt="One Source logo" />
        </router-link>
      </b-navbar-item>
      <b-navbar-item v-if="menuOpened" class="py-0 my-0 is-pulled-right" @click.native="() => onCloseMenu()">
        <div class="mx-4">
          <b-icon icon="close" />
        </div>
      </b-navbar-item>
      <b-navbar-item v-else tag="div" class="py-0 my-0 is-pulled-right">
        <div class="mx-4">
          <b-icon icon="signal" :type="isOnline ? 'is-success' : 'is-warning'"></b-icon>
        </div>
        <LocationSelector :currentLocation="currentLocation" :locations="locations" @setCurrent="setCurrent" />
        <LocaleSelector class="ml-2" />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import LocationSelector from '@/components/LocationSelector.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';
import { hasRole } from '@/router/roles';

export default {
  components: { LocationSelector, LocaleSelector },
  props: {
    currentLocation: Object,
    locations: {
      type: Array,
      required: true,
    },
    menuOpened: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isDeprecatedUser() {
      return !hasRole('OneSourceAccess');
    },
  },
  methods: {
    setCurrent(value) {
      this.$emit('set-current', value);
    },
    onCloseMenu() {
      this.$emit('close-side-bar');
    },
    onToggleMenu() {
      this.$emit('toggle-side-bar');
    },
  },
};
</script>

<style lang="scss">
.nav-bar {
  z-index: 40 !important;

  .navbar-brand {
    justify-content: space-between;
    width: 100vw;

    .icon-burger {
      cursor: pointer;
    }

    a {
      color: #fff;
      display: inline-flex;
      text-decoration: none;
    }
  }
}
</style>
