<template>
  <div class="home" v-if="currentLocation && !error">
    <section class="hero is-taster-blue" v-if="hero">
      <div class="hero-body is-fixed">
        <h1 class="title">{{ $t('home.myKitchen') }}</h1>
        <h2 class="subtitle">{{ $t('home.myKitchenMessage') }}</h2>
      </div>
    </section>
    <section class="py-3 mb-4 is-absolute">
      <div class="columns is-multiline">
        <div class="column is-one-third" v-if="isSupplyOrderValidator || isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer is-relative mx-3"
            @click="$router.push({ name: 'validationList' })"
          >
            <div class="notification-tag">
              <div v-if="fetchAllAwaitingValidationLoading">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
              <b-tag v-else :type="numberOfOrdersToValidate ? 'is-taster-blue' : 'is-success is-light'" rounded>
                {{ numberOfOrdersToValidate }}
              </b-tag>
            </div>
            <div class="is-size-1">🧾</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('menu.validation') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.validation') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isController || isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer is-relative mx-3"
            @click="$router.push({ name: 'receptionControlListDeliveries' })"
          >
            <div class="notification-tag">
              <div v-if="fetchAllAwaitingDeliveryLoading">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
              <b-tag v-else :type="numberOfAwaitingDeliveries ? 'is-taster-blue' : 'is-success is-light'" rounded>
                {{ numberOfAwaitingDeliveries }}
              </b-tag>
            </div>
            <div class="is-size-1">🚛</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('menu.receptionControlListDeliveries') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.receptionControl') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isController || isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'inventory' })"
          >
            <div class="is-size-1">📦</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">{{ $t('menu.inventory') }}</div>
            <div class="has-text-grey is-size-7">{{ $t('home.inventory') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isSupplyOrderValidator || isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'kitchenEventList' })"
          >
            <div class="is-size-1">📅</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('menu.kitchenSchedule') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.kitchenSchedule') }}</div>
          </div>
        </div>
        <div v-if="isStockViewer || isSupplyAdmin" class="column is-one-third">
          <router-link :to="{ name: 'stockOutList' }">
            <div class="box box-tiled has-text-centered has-cursor-pointer mx-3">
              <div class="is-size-1">🔃</div>
              <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
                {{ $t('menu.stockInOut') }}
              </div>
              <div class="is-size-7 has-text-grey">
                {{ $t('home.stockInOut') }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="!isController && !isSupplyAdmin && !isSupplyOrderValidator">
        <b-message title="🚫" type="is-taster-blue" :closable="false">
          {{ $t('home.notAuthorized') }}
        </b-message>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { hasRole } from '@/router/roles';
import { restaurantsMgr, errorHandler, purchaseOrdersMgr } from '@/mixins';

export default {
  props: {
    hero: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [restaurantsMgr, errorHandler, purchaseOrdersMgr],
  computed: {
    isSupplyAdmin() {
      return hasRole('SupplyAdmin');
    },
    isController() {
      return hasRole('SupplyController');
    },
    isSupplyOrderValidator() {
      return hasRole('SupplyOrderValidator');
    },
    isStockViewer() {
      return hasRole('StockInViewer') || hasRole('StockOutViewer') || hasRole('StockTransferViewer');
    },
    numberOfAwaitingDeliveries() {
      return this.awaitingDeliveryOrders.filter(({ delivery_date: deliveryDate, reception_date: receptionDate }) =>
        this.$dayjs(receptionDate || deliveryDate).isToday(),
      ).length;
    },
  },
  watch: {
    currentLocation() {
      this.unsetError();
      this.fetchPurchases();
    },
  },
  created() {
    this.fetchPurchases();
  },
  methods: {
    ...mapActions('stock', ['fetchAwaitingDeliveryTransfers']),

    fetchPurchases() {
      if (!this.currentLocation) {
        return;
      }

      if (this.isController) {
        this.fetchAwaitingDelivery({ locationUuid: this.currentLocation.uuid });
      }
      if (this.isSupplyOrderValidator) {
        this.fetchAwaitingValidation({ locationUuid: this.currentLocation.uuid });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-tag {
  position: absolute;
  top: -10px;
  right: -10px;
}
.had-cursor-pointer {
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    transform: scale(1.02);
  }
}
.had-cursor-pointer:hover {
  box-shadow: 0 4px 6px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.box-tiled {
  border-radius: 0 !important;
  height: 100%;
}
</style>
