import clientHook from '@/apis';

const GatewaySupplyClient = require('@tasterkitchens/gateway-supply-client');

const awaitingDeliveryApi = new GatewaySupplyClient.AwaitingDeliveryOrdersApi();
awaitingDeliveryApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

const awaitingValidationApi = new GatewaySupplyClient.AwaitingValidationOrdersApi();
awaitingValidationApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

const purchaseOrdersApi = new GatewaySupplyClient.PurchaseOrdersApi();
purchaseOrdersApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

const inventoryApi = new GatewaySupplyClient.InventoriesApi();
inventoryApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

const localizedProductsApi = new GatewaySupplyClient.LocalizedProductsApi();
localizedProductsApi.apiClient.basePath = process.env.VUE_APP_GATEWAY_SUPPLY_ENDPOINT;

export default {
  fetchAwaitingValidationPurchaseOrder(uuid) {
    return clientHook(awaitingValidationApi).getAwaitingValidationOrders(uuid);
  },
  fetchUpcomingPurchaseOrders(opts) {
    return clientHook(purchaseOrdersApi).listPurchaseOrders(opts);
  },
  addProductToCurrentInventory(locationUuid, payload, refreshProductNeeds = false) {
    return clientHook(inventoryApi).addProductToCurrentInventory(locationUuid, payload, { refreshProductNeeds });
  },
  fetchLocalizedProductAvailableSubstitutes(productUuid, locationUuid, queryParams) {
    return clientHook(localizedProductsApi).listLocalizedProductAvailableSubstitutes(
      productUuid,
      locationUuid,
      queryParams,
    );
  },
  createProductOrderForAwaitingValidationOrder(purchaseUuid, bodyRequest) {
    return clientHook(awaitingValidationApi).createProductOrderForAwaitingValidationOrder(purchaseUuid, bodyRequest);
  },
  createProductOrderForAwaitingDeliveryOrder(purchaseUuid, bodyRequest) {
    return clientHook(awaitingDeliveryApi).createProductOrderForAwaitingDeliveryOrder(purchaseUuid, bodyRequest);
  },
};
