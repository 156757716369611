import temperatureControlsApi from '@/apis/purchaseOrders/temperatureControls';
import { temperatureControlsTypes } from "./purchaseOrdersTypes"

export default {
    state: {
        temperatures: {
            total: 0,
            items: []
        },
        fetchAllTemperatures: {
            pending: false,
        },
    },
    mutations: {
        [temperatureControlsTypes.FETCH_ALL_TEMPERATURES](state, temperatures) {
            state.temperatures.total = temperatures.total;
            state.temperatures.items = temperatures.items;
        },
        [temperatureControlsTypes.FETCH_ALL_TEMPERATURES_PENDING]: (state, pending) => state.fetchAllTemperatures.pending = pending,
    },
    actions: {
        async fetchAllTemperatures({ commit }, opts) {
            try {
                commit(temperatureControlsTypes.FETCH_ALL_TEMPERATURES_PENDING, true)
                let temperatures = await temperatureControlsApi.fetchAll(opts)
                commit(temperatureControlsTypes.FETCH_ALL_TEMPERATURES, temperatures)
                return temperatures
            } finally {
                commit(temperatureControlsTypes.FETCH_ALL_TEMPERATURES_PENDING, false)
            }
        },
        async createOneTemperature(_, { locationUuid, categoryUuid, minTemp, maxTemp }) {
            return await temperatureControlsApi.createOne({
                location_uuid: locationUuid,
                category_uuid: categoryUuid,
                min_temp: minTemp,
                max_temp: maxTemp,
            })
        },
        async deleteOneTemperature(_, { uuid }) {
            return await temperatureControlsApi.removeOne(uuid)
        }
    },
    getters: {
        getTemperatureByCategoryUuidAndLocation: (state) => (categoryUuid, locationUuid) => {
            return state.temperatures.items.find((temperature) => {
                return temperature.category_uuid === categoryUuid && temperature.location_uuid === locationUuid
            })
        }
    },
}
