import restaurantsApi from '@/apis/purchaseOrders/restaurants';
import { locationsTypes } from './restaurantsTypes.js';
import { VueOfflineStorage } from 'vue-offline';

export default {
  state: {
    locations: [],
    nestedLocations: [],
    currentLocation: null,
    requests: {
      fetchAllLocations: 0,
    },
    fetchAllLocations: {
      pending: false,
    },
    fetchAllNestedLocations: {
      pending: false,
    },
  },
  mutations: {
    [locationsTypes.FETCH_ALL]: (state, locations) => (state.locations = locations),
    [locationsTypes.FETCH_ALL_PENDING]: (state, pending) => (state.fetchAllLocations.pending = pending),
    [locationsTypes.FETCH_NESTED]: (state, locations) => (state.nestedLocations = locations),
    [locationsTypes.FETCH_NESTED_PENDING]: (state, pending) => (state.fetchAllNestedLocations.pending = pending),
    [locationsTypes.SET_CURRENT]: (state, location) => {
      state.currentLocation = location;
      VueOfflineStorage.set('currentLocation', location);
    },
  },
  actions: {
    async fetchAllLocations({ commit }) {
      try {
        commit(locationsTypes.FETCH_ALL_PENDING, true);
        let locations = await restaurantsApi.fetchAllLocations();
        commit(locationsTypes.FETCH_ALL, locations);
        return locations;
      } finally {
        commit(locationsTypes.FETCH_ALL_PENDING, false);
      }
    },
    setCurrentLocation: ({ commit }, location) => commit(locationsTypes.SET_CURRENT, location),
  },
  getters: {
    getLocationByUuid: (state) => (uuid) => state.locations.find((_) => _.uuid === uuid),
  },
};
