<template>
  <div class="location-selector">
    <b-button icon-left="store" type="is-taster-blue" inverted @click="isModalActive = !isModalActive">
      {{ currentLocation ? currentLocation.id : $t('locationSelector.selectKitchen') }}
    </b-button>
    <b-modal
      v-model="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      max-height="500px"
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="card">
        <div class="modal-card-head">
          <div class="modal-card-title">{{ $t('locationSelector.selectKitchen') }}</div>
        </div>
        <div class="card-content">
          <b-select :placeholder="$t('locationSelector.selectKitchen')" @input="setCurrent" icon="earth" expanded>
            <option v-for="location in locations" :value="location" :key="location.uuid">
              {{ location.id }}
            </option>
          </b-select>
        </div>
        <div class="modal-card-foot">
          <button class="button" type="button" @click="isModalActive = !isModalActive">{{ $t('done') }}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'LocationSelector',
  props: {
    currentLocation: { type: Object },
    locations: { type: Array },
  },
  data: function() {
    return {
      isModalActive: false,
    };
  },
  methods: {
    setCurrent(location) {
      this.$emit('setCurrent', location);
    },
  },
};
</script>
