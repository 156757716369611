import purchaseOrders from "./purchaseOrders"
import inventories from "./inventories"
import temperatureControls from "./temperatureControls"
import schedules from "./schedules"

export default {
    namespaced: true,
    state: {
        ...purchaseOrders.state,
        ...inventories.state,
        ...temperatureControls.state,
        ...schedules.state,
    },
    mutations: {
        ...purchaseOrders.mutations,
        ...inventories.mutations,
        ...temperatureControls.mutations,
        ...schedules.mutations,
    },
    actions: {
        ...purchaseOrders.actions,
        ...inventories.actions,
        ...temperatureControls.actions,
        ...schedules.actions,
    },
    getters: {
        ...purchaseOrders.getters,
        ...temperatureControls.getters,
        ...schedules.getters,
    },
}