import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const restaurantsApi = new PurchaseOrdersClient.RestaurantsApi();
restaurantsApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
    fetchAllLocations() {
        return clientHook(restaurantsApi).appApisV1RestaurantsListLocations();
    },
    getCurrentShift(uuid) {
        return clientHook(restaurantsApi).appApisV1RestaurantsGetCurrentShift(uuid);
    },
}
