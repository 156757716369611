import Vue from 'vue';
import VueRouter from 'vue-router';

import Main from '@/views/Main';
import Home from '@/views/Home';
import Profile from '@/views/Profile/Profile';
import kitchenToolsRoutes from './kitchenTools.js';
import adminRoutes from './admin.js';
import DeprecationPage from '@/views/DeprecationPage.vue';
import { roles } from '@/router/roles';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Main,
    meta: { authorize: [] },
    children: [
      {
        name: 'home',
        path: '',
        display: false,
        component: Home,
        meta: { authorize: [], deprecated: true },
      },
      {
        path: 'profile',
        name: 'profile',
        display: false,
        component: Profile,
        meta: { authorize: [] },
      },
      kitchenToolsRoutes,
      adminRoutes,
    ],
  },
  {
    path: '/deprecated',
    name: 'deprecated',
    component: DeprecationPage,
    meta: { authorize: [] },
  },
  {
    path: '*',
    redirect: '/',
    meta: { authorize: [] },
  },
];

const router = new VueRouter({
  routes,
});

const displayNotAuthorizedToast = () => {
  Vue.prototype.$buefy.toast.open({
    message: 'Unauthorized!',
    type: 'is-danger',
  });
};

export const isAuthorized = (authorize, toast) => {
  if (toast === undefined) toast = true;
  if (!authorize.length) {
    return true;
  } else if (Vue.prototype.$auth.getUserRoles().filter((value) => authorize.includes(value)).length) {
    return true;
  }
  if (toast) displayNotAuthorizedToast();
  return false;
};

const handleRedirect = (to, from, next) => {
  const { authorize, deprecated } = to.meta;

  const hasOneSourceAccess = Vue.prototype.$auth.getUserRoles().includes(roles.OneSourceAccess);
  const canUserNavigate = isAuthorized(authorize) && (hasOneSourceAccess || !deprecated);
  Vue.prototype.$errorReporting.setUser(Vue.prototype.$auth.user.sub);
  if (canUserNavigate) {
    return next();
  }
  return next({ name: hasOneSourceAccess ? 'home' : 'deprecated' });
};

router.beforeEach((to, from, next) => {
  if (!Vue.prototype.$auth.isAuthenticated) {
    Vue.prototype.$auth.signIn().then(() => {
      handleRedirect(to, from, next);
    });
  } else {
    handleRedirect(to, from, next);
  }
});

export default router;
