import { mimes, min_value, required, required_if, integer, between } from 'vee-validate/dist/rules';
import { configure, extend } from 'vee-validate';
import i18n from './i18n';

configure({
  defaultMessage: (_, values) => {
    return i18n.t(`vee.${values._rule_}`, values);
  },
});

extend('batchSize', {
  validate(value, { batchSize }) {
    return value % batchSize === 0;
  },
  params: ['batchSize'],
});

extend('mimes', mimes);
extend('integer', integer);
extend('between', between);
extend('min_value', min_value);
extend('required', required);
extend('required_if', required_if);
extend('integer', integer);
extend('greater_than', {
  validate(value, { min }) {
    return value > min;
  },
  params: ['min'],
});
