export default {
    FETCH_ALL_PRODUCTS: "FETCH_ALL_PRODUCTS",
    FETCH_ALL_SUPPLIERS: "FETCH_ALL_SUPPLIERS",
    FETCH_ALL_HUBS: "FETCH_ALL_HUBS",
    FETCH_ALL_CATEGORIES: "FETCH_ALL_CATEGORIES",
    FETCH_ALL_PRODUCTS_PENDING: "FETCH_ALL_PRODUCTS_PENDING",
    FETCH_ALL_SUPPLIERS_PENDING: "FETCH_ALL_SUPPLIERS_PENDING",
    FETCH_ALL_HUBS_PENDING: "FETCH_ALL_HUBS_PENDING",
    FETCH_ALL_CATEGORIES_PENDING: "FETCH_ALL_CATEGORIES_PENDING",
}
