import { roles } from './roles';

const Index = () => import(/* webpackChunkName: "reception-control" */ '@/views/KitchenTools/Index.vue');
const Home = () => import(/* webpackChunkName: "reception-control" */ '@/views/KitchenTools/Home.vue');
const ListDeliveries = () =>
  import(/* webpackChunkName: "reception-control" */ '@/views/KitchenTools/ReceptionControl/ListDeliveries.vue');
const Delivery = () => import(/* webpackChunkName: "reception" */ '@/views/KitchenTools/ReceptionControl/Delivery.vue');
const OrderStatus = () =>
  import(/* webpackChunkName: "reception" */ '@/components/KitchenTools/ReceptionControls/OrderStatus.vue');
const TemperatureControls = () =>
  import(/* webpackChunkName: "reception" */ '@/components/KitchenTools/ReceptionControls/TemperatureControls.vue');
const ItemsDeclaration = () =>
  import(/* webpackChunkName: "reception" */ '@/components/KitchenTools/ReceptionControls/ItemsDeclaration.vue');
const DocumentsProof = () =>
  import(/* webpackChunkName: "reception" */ '@/components/KitchenTools/ReceptionControls/DocumentsProof.vue');
const Summary = () =>
  import(/* webpackChunkName: "reception" */ '@/components/KitchenTools/ReceptionControls/Summary.vue');
const ReceptionControlSuccess = () =>
  import(/* webpackChunkName: "reception" */ '@/components/KitchenTools/ReceptionControls/ReceptionControlSuccess.vue');
const CurrentInventories = () =>
  import(/* webpackChunkName: "inventory" */ '@/views/KitchenTools/Inventory/CurrentInventories.vue');
const EditCurrentInventory = () =>
  import(/* webpackChunkName: "inventory" */ '@/views/KitchenTools/Inventory/EditCurrentInventory.vue');
const Orders = () => import(/* webpackChunkName: "orders" */ '@/views/KitchenTools/Orders.vue');
const OrderHistory = () => import(/* webpackChunkName: "orders" */ '@/views/KitchenTools/Orders/OrderHistory.vue');
const OrderDetails = () => import(/* webpackChunkname: "orders" */ '@/views/KitchenTools/Orders/OrderDetails.vue');
const KitchenSchedule = () => import(/* webpackChunkName: "schedule" */ '@/views/KitchenTools/KitchenSchedule.vue');
const StockEventList = () =>
  import(/* webpackChunkName: "stock-in-out" */ '@/views/KitchenTools/StockInOut/StockEventList.vue');
const StockInList = () =>
  import(/* webpackChunkName: "stock-in-out" */ '@/views/KitchenTools/StockInOut/Lists/StockInList.vue');
const StockOutList = () =>
  import(/* webpackChunkName: "stock-in-out" */ '@/views/KitchenTools/StockInOut/Lists/StockOutList.vue');
const TransferList = () =>
  import(/* webpackChunkName: "stock-in-out" */ '@/views/KitchenTools/StockInOut/Lists/TransferList.vue');
const ValidationList = () =>
  import(/* webpackChunkName: "orders" */ '@/views/KitchenTools/Validation/ValidationList.vue');
const Ordering = () => import(/* webpackChunkName: "orders" */ '@/views/KitchenTools/Validation/Ordering.vue');

const router = {
  path: '/my-kitchen',
  keyName: 'myKitchen',
  menuRedirect: 'myKitchenHome',
  display: true,
  menu: true,
  icon: 'store',
  component: Index,
  meta: { authorize: [roles.SupplyAdmin, roles.SupplyController, roles.SupplyOrderValidator] },
  children: [
    {
      path: '',
      name: 'myKitchenHome',
      keyName: 'myKitchenHome',
      display: false,
      menu: false,
      component: Home,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyController], deprecated: true },
    },
    {
      path: 'validation',
      redirect: { name: 'validationList' },
    },
    {
      path: 'validation/:id',
      redirect: { name: 'validationDetails' },
    },
    {
      path: 'orders',
      name: 'validation',
      keyName: 'validation',
      displayIcon: '',
      display: true,
      menu: false,
      icon: 'format-list-checks',
      component: Orders,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyController], deprecated: true },
      children: [
        {
          name: 'validationList',
          path: 'validation',
          component: ValidationList,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyOrderValidator], deprecated: true },
        },
        {
          name: 'validationDetails',
          path: 'validation/:id',
          component: Ordering,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyOrderValidator], deprecated: true },
        },
        {
          name: 'historyList',
          path: 'history',
          component: OrderHistory,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyOrderValidator], deprecated: true },
        },
        {
          name: 'historyDetails',
          path: ':uuid',
          component: OrderDetails,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyOrderValidator], deprecated: true },
          props: true,
        },
      ],
    },
    {
      name: 'receptionControlListDeliveries',
      keyName: 'receptionControlListDeliveries',
      path: 'reception-control',
      display: true,
      menu: true,
      icon: 'truck',
      component: ListDeliveries,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
    },
    {
      name: 'receptionControl',
      path: 'reception-control/purchase-order/:uuid',
      props: true,
      menu: false,
      component: Delivery,
      children: [
        {
          name: 'orderStatus',
          path: 'status',
          menu: false,
          component: OrderStatus,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
        },
        {
          name: 'temperatureControls',
          path: 'temperature-controls',
          menu: false,
          component: TemperatureControls,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
        },
        {
          name: 'itemsDeclaration',
          path: 'items-declaration',
          menu: false,
          component: ItemsDeclaration,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
        },
        {
          name: 'documentsProof',
          path: 'documents-proof',
          menu: false,
          component: DocumentsProof,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
        },
        {
          name: 'receptionSummary',
          path: 'summary',
          menu: false,
          component: Summary,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
        },
        {
          name: 'receptionSuccess',
          path: 'success',
          menu: false,
          component: ReceptionControlSuccess,
          meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
        },
      ],
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyController] },
    },
    {
      name: 'inventory',
      keyName: 'inventory',
      path: 'inventory',
      displayIcon: '',
      display: true,
      menu: false,
      icon: 'package-variant',
      component: CurrentInventories,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyController], deprecated: true },
    },
    {
      name: 'editInventory',
      keyName: 'editInventory',
      path: 'inventory/edit',
      display: false,
      component: EditCurrentInventory,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyController], deprecated: true },
    },
    {
      name: 'kitchenEventList',
      keyName: 'kitchenSchedule',
      path: 'schedule',
      icon: 'calendar',
      display: true,
      component: KitchenSchedule,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyOrderValidator], deprecated: true },
    },
    {
      name: 'stockInOut',
      keyName: 'stockInOut',
      path: 'stock',
      display: true,
      displayIcon: '',
      menu: false,
      icon: 'sync',
      component: StockEventList,
      meta: {
        authorize: [roles.StockInViewer, roles.StockOutViewer, roles.StockTransferViewer],
        deprecated: true,
      },
      children: [
        {
          name: 'stockOutList',
          path: 'stock-out',
          component: StockOutList,
          meta: {
            authorize: [roles.StockOutViewer],
            deprecated: true,
          },
        },
        {
          name: 'stockInList',
          path: 'stock-in',
          component: StockInList,
          meta: {
            authorize: [roles.StockInViewer],
            deprecated: true,
          },
        },
        {
          name: 'transferList',
          path: 'transfer',
          component: TransferList,
          meta: {
            authorize: [roles.StockTransferViewer],
            deprecated: true,
          },
        },
      ],
    },
  ],
};

export default router;
