<template>
  <div class="main">
    <b-loading v-if="isLoading" v-model="isLoading" :is-full-page="true" />
    <template v-else>
      <NavBar
        v-if="!isIframe"
        :current-location="currentLocation"
        :locations="sortedAuthorizedLocations"
        :menu-opened="menuOpened"
        @set-current="setCurrent"
        @toggle-side-bar="() => (menuOpened = !menuOpened)"
        @close-side-bar="() => (menuOpened = false)"
      />
      <div class="main-content">
        <div v-if="!isIframe" class="side-bar">
          <SideBar
            :navigation="navigation"
            :currentLocation="currentLocation"
            :locations="sortedAuthorizedLocations"
            :number-awaiting-orders="numberOfOrdersToValidate"
            :opened="menuOpened"
            @close-side-bar="() => (menuOpened = false)"
          />
        </div>
        <div :key="language" id="router-content" class="router-content">
          <b-message type="is-warning" size="is-small" has-icon icon="earth-off" v-if="isOffline && showOfflineMessage">
            <div>{{ $t('notConnected') }}</div>
          </b-message>
          <router-view />
          <div class="error-message container is-fluid py-3 mb-4 is-absolute" v-if="error">
            <b-message :closable="false" title="Error" type="is-danger" has-icon> {{ error }} </b-message>
          </div>
          <div class="error-message container is-fluid py-3 mb-4 is-absolute" v-if="!currentLocation">
            <b-message :closable="false" title="Select location" type="is-warning" has-icon>
              <div>
                <span>{{ $t('locationSelector.error') }} </span>
                <b-field>
                  <b-select :placeholder="$t('locationSelector.selectKitchen')" @input="setCurrent" icon="earth">
                    <option v-for="location in sortedAuthorizedLocations" :value="location" :key="location.uuid">
                      {{ location.id }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </b-message>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar';
import NavBar from '@/components/NavBar';
import { purchaseOrdersMgr, restaurantsMgr, errorHandler, authHelpers } from '@/mixins';
import { belongsTo, groups } from '@/router/groups';

const mediaQuery = window.matchMedia('(min-width: 1024px)');
const noOfflineMessageRouteName = ['validationList', 'validationDetails'];

export default {
  name: 'Main',
  components: { SideBar, NavBar },
  mixins: [purchaseOrdersMgr, restaurantsMgr, errorHandler, authHelpers],
  data() {
    return {
      isLoading: false,
      menuOpened: false,
    };
  },
  computed: {
    isIframe() {
      return window.top !== window.self;
    },
    navigation() {
      return this.$router.options.routes[0].children;
    },
    language() {
      return this.$root.$i18n.locale;
    },
    showOfflineMessage() {
      if (belongsTo(groups.ValidationV3)) {
        return !noOfflineMessageRouteName.includes(this.$route.name);
      }
      return true;
    },
  },
  watch: {
    currentLocation(location) {
      if (!location) {
        return;
      }

      this.fetchNumberOfOrdersToValidate({
        locationUuid: location.uuid,
      });
    },
    language: {
      handler(lang) {
        this.$dataLayerPush({
          event: 'language_update',
          lang,
        });
      },
      immediate: true,
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.fetchLocations();

      const currentLocation = this.$offlineStorage.get('currentLocation');
      if (this.$route.query.location_uuid) {
        const authorizedLocation = this.authorizedLocations.find(
          ({ uuid }) => this.$route.query.location_uuid === uuid,
        );

        if (authorizedLocation) {
          window.localStorage.clear();
          this.setCurrentLocation(authorizedLocation);
        }
      } else if (
        currentLocation &&
        // be sure that local storage contains correct infos
        currentLocation.location_country &&
        currentLocation.location_country.timezone &&
        currentLocation.location_country.currency_code
      ) {
        this.setCurrentLocation(currentLocation);
      } else if (this.authorizedLocations.length === 1) {
        this.setCurrentLocation(this.authorizedLocations[0]);
      }

      if (this.currentLocation) {
        await this.fetchNumberOfOrdersToValidate({
          locationUuid: this.currentLocation.uuid,
        });
      }
    } finally {
      this.isLoading = false;
      let userContract = '';
      let userType = '';
      const regexTasterPartner = /.tasterpartner.com$/;
      const regexTasterEmployee = /.taster.com$/;

      if (regexTasterPartner.test(this.$auth.user.email)) {
        userContract = 'partner';
      } else if (regexTasterEmployee.test(this.$auth.user.email)) {
        userContract = 'employee';
      }

      if (belongsTo(groups.KitchenManagers) || belongsTo(groups.FranchiseManagers)) {
        userType = 'manager';
      } else if (belongsTo(groups.TasterCooks) || belongsTo(groups.FranchiseCooks)) {
        userType = 'cook';
      }

      this.$dataLayerPush({
        event: 'login',
        user_email: this.$auth.user.email,
        user_id: this.$auth.user.sub,
        kitchen_labels: this.locations.map(({ label }) => label),
        lang: this.language,
        user_type: userType,
        user_contract: userContract,
      });
    }
  },
  mounted() {
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', this.onMediaQueryChange);
    } else {
      mediaQuery.addListener(this.onMediaQueryChange);
    }
  },
  beforeDestroy() {
    if (mediaQuery.removeEventListener) {
      mediaQuery.removeEventListener('change', this.onMediaQueryChange);
    } else {
      mediaQuery.removeListener(this.onMediaQueryChange);
    }
  },
  methods: {
    setCurrent(location) {
      this.setCurrentLocation(location);
    },
    onMediaQueryChange(event) {
      if (event.matches) {
        this.menuOpened = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app.scss';

.main-content {
  background-color: #fafafa;
  height: 100vh;
  padding-top: 52px;
  position: fixed;
  top: 0;
  width: 100vw;
}

.side-bar {
  position: fixed;
  height: calc(100vh - 56px);
  z-index: 90;
}

.router-content {
  overflow-y: auto;
  position: fixed;
  top: 52px;
  bottom: 0;
  padding: 10px 20px 0 20px;
  width: 100vw;

  @media screen and (min-width: $desktop) {
    padding-left: 90px;
  }
}
</style>
