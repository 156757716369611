import { mapState, mapActions, mapGetters } from 'vuex';

export const purchaseOrdersMgr = {
  computed: {
    ...mapState({
      statuses: (state) => state.purchaseOrders.statuses,
      currentPurchaseOrder: (state) => state.purchaseOrders.currentPurchaseOrder,
      purchaseOrders: (state) => state.purchaseOrders.purchaseOrders.items,
      purchaseOrdersTotal: (state) => state.purchaseOrders.purchaseOrders.total,
      purchaseHistory: (state) => state.purchaseOrders.purchaseHistory.items,
      purchaseHistoryTotal: (state) => state.purchaseOrders.purchaseHistory.total,
      fetchAllPurchaseOrdersLoading: (state) => state.purchaseOrders.fetchAllPurchaseOrders.pending,
      delivers: (state) => state.purchaseOrders.delivers,
      deliversLoading: (state) => state.purchaseOrders.fetchAllDelivers.pending,
      purchaseOrderLoading: (state) => state.purchaseOrders.fetchOne.pending,
      temperatures: (state) => state.purchaseOrders.temperatures.items,
      temperaturesTotal: (state) => state.purchaseOrders.temperatures.total,
      fetchAllTemperaturesLoading: (state) => state.purchaseOrders.fetchAllTemperatures.pending,
      draftedOrders: (state) => state.purchaseOrders.draftedOrders.items,
      draftedOrdersTotal: (state) => state.purchaseOrders.draftedOrders.total,
      suggestedOrders: (state) => state.purchaseOrders.suggestedOrders.items,
      suggestedOrdersTotal: (state) => state.purchaseOrders.suggestedOrders.total,
      awaitingValidationOrders: (state) => state.purchaseOrders.awaitingValidationOrders.items,
      awaitingValidationOrdersTotal: (state) => state.purchaseOrders.awaitingValidationOrders.total,
      validatedOrders: (state) => state.purchaseOrders.validatedOrders.items,
      validatedOrdersTotal: (state) => state.purchaseOrders.validatedOrders.total,
      awaitingDeliveryOrders: (state) => state.purchaseOrders.awaitingDeliveryOrders.items,
      awaitingDeliveryOrdersTotal: (state) => state.purchaseOrders.awaitingDeliveryOrders.total,
      deliveredOrders: (state) => state.purchaseOrders.deliveredOrders.items,
      deliveredOrdersTotal: (state) => state.purchaseOrders.deliveredOrders.total,
      fetchAllDraftedLoading: (state) => state.purchaseOrders.fetchAllDraftedOrders.pending,
      fetchAllSuggestedLoading: (state) => state.purchaseOrders.fetchAllSuggestedOrders.pending,
      fetchAllAwaitingValidationLoading: (state) => state.purchaseOrders.fetchAllAwaitingValidationOrders.pending,
      fetchAllValidatedLoading: (state) => state.purchaseOrders.fetchAllValidatedOrders.pending,
      fetchAllAwaitingDeliveryLoading: (state) => state.purchaseOrders.fetchAllAwaitingDeliveryOrders.pending,
      fetchAllDeliveredLoading: (state) => state.purchaseOrders.fetchAllDeliveredOrders.pending,
      awaitingValidationPurchaseQuantities: (state) => state.purchaseOrders.awaitingValidationPurchaseQuantities,
      events: (state) => state.purchaseOrders.events,
      eventsLoading: (state) => state.purchaseOrders.fetchEvents.pending,
      getOneEventLoading: (state) => state.purchaseOrders.getOneEvent.pending,
      validateEventLoading: (state) => state.purchaseOrders.validateEvent.pending,
      createEventLoading: (state) => state.purchaseOrders.createEvent.pending,
      editEventLoading: (state) => state.purchaseOrders.editEvent.pending,
      skipDayLoading: (state) => state.purchaseOrders.skipDay.pending,
      deleteEventLoading: (state) => state.purchaseOrders.deleteEvent.pending,
      numberOfOrdersToValidate: (state) => state.purchaseOrders.numberOfOrdersToValidate,
      searchListPurchaseFilters: (state) => state.purchaseOrders.searchListPurchaseFilters,
    }),
    ...mapGetters('purchaseOrders', ['getPurchaseOrdersByStatus', 'getTemperatureByCategoryUuidAndLocation']),
  },
  methods: {
    ...mapActions('purchaseOrders', {
      fetchGatewayAwaitingValidationPurchaseOrder: 'fetchGatewayAwaitingValidationPurchaseOrder',
      fetchAllPurchaseOrders: 'fetchAllPurchaseOrders',
      fetchAllDelivers: 'fetchAllDelivers',
      fetchOnePurchaseOrder: 'fetchOne',
      fetchOnePurchaseOrderByStatus: 'fetchOneByStatus',
      fetchOnePurchaseOrderTasksByStatus: 'fetchTasksByStatus',
      unsetCurrentPurchase: 'unsetCurrentPurchase',
      generatePurchaseOrder: 'generatePurchaseOrder',
      generateProductOrder: 'generateProductOrder',
      fetchAllTemperatures: 'fetchAllTemperatures',
      createOneTemperature: 'createOneTemperature',
      deleteOneTemperature: 'deleteOneTemperature',
      fetchAllDrafted: 'fetchAllDrafted',
      fetchAllSuggested: 'fetchAllSuggested',
      fetchAllAwaitingValidation: 'fetchAllAwaitingValidation',
      fetchAllValidated: 'fetchAllValidated',
      fetchAllAwaitingDelivery: 'fetchAllAwaitingDelivery',
      fetchAllDelivered: 'fetchAllDelivered',
      fetchMoreOrders: 'fetchMoreOrders',
      fetchNumberOfOrdersToValidate: 'fetchNumberOfOrdersToValidate',
      resetOrdersByStatus: 'resetOrdersByStatus',
      updateProductByStatus: 'updateProductByStatus',
      updatePurchaseByStatus: 'updatePurchaseByStatus',
      updateDeliveryCharges: 'updatePurchaseDeliveryCharges',
      createPurchaseOrderByStatus: 'createPurchaseOrderByStatus',
      createProductOrderByStatus: 'createProductOrderByStatus',
      cancelPurchaseOrderByStatus: 'cancelPurchaseOrderByStatus',
      cancelDeliveryCharges: 'cancelPurchaseDeliveryCharges',
      checkPurchaseOrderByStatus: 'checkPurchaseOrderByStatus',
      sendPurchaseOrderByStatus: 'sendPurchaseOrderByStatus',
      changeStatus: 'changeStatus',
      uploadDeliveryNote: 'uploadDeliveryNote',
      uploadProductOrderDamageProof: 'uploadProductOrderDamageProof',
      delayAwaitingValidationOrder: 'delayAwaitingValidationOrder',
      advanceAwaitingValidationOrder: 'advanceAwaitingValidationOrder',
      saveAwaitingValidationQuantity: 'saveAwaitingValidationQuantity',
      removeAwaitingValidationQuantity: 'removeAwaitingValidationQuantity',
      updateAwaitingValidationProductQuantities: 'updateAwaitingValidationProductQuantities',
      updateDeliveredProductPrice: 'updateDeliveredProductPrice',
      exportData: 'exportData',
      getPurchaseHistory: 'getPurchaseHistory',
      deletePurchaseOrderByStatus: 'deletePurchaseOrderByStatus',
      fetchAllEvents: 'fetchAllEvents',
      getEvent: 'getEvent',
      validateEvent: 'validateEvent',
      createEvent: 'createEvent',
      editEvent: 'editEvent',
      skipDay: 'skipDay',
      deleteEvent: 'deleteEvent',
      generateValidatedPDF: 'generateValidatedPDF',
      sendMailValidated: 'sendMailValidated',
      saveDraftMailForDeliveredOrders: 'saveDraftMailForDeliveredOrders',
      changePurchaseFilters: 'changePurchaseFilters',
    }),
    ...mapActions(['setError', 'processError']),
    async fetchPurchaseOrders(opts) {
      if (!this.fetchAllPurchaseOrdersLoading) {
        try {
          await this.fetchAllPurchaseOrders(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchDrafted(opts) {
      if (!this.fetchAllDraftedLoading) {
        try {
          await this.fetchAllDrafted(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchSuggested(opts) {
      if (!this.fetchAllSuggestedLoading) {
        try {
          await this.fetchAllSuggested(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchAwaitingValidation(opts) {
      if (!this.fetchAllAwaitingValidationLoading) {
        try {
          await this.fetchAllAwaitingValidation(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async delayValidationDeadline(uuid, validationDueDate) {
      try {
        await this.updatePurchaseByStatus(uuid, { validationDueDate });
      } catch (error) {
        this.setError(error);
        this.processError(error);
      }
    },
    async fetchValidated(opts) {
      if (!this.fetchAllValidatedLoading) {
        try {
          await this.fetchAllValidated(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchAwaitingDelivery(opts) {
      if (!this.fetchAllAwaitingDeliveryLoading) {
        try {
          await this.fetchAllAwaitingDelivery(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchDelivered(opts) {
      if (!this.fetchAllDeliveredLoading) {
        try {
          await this.fetchAllDelivered(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchDelivers(opts) {
      if (!this.fetchPurchaseOrdersLoading && this.delivers.length === 0) {
        try {
          await this.fetchAllDelivers(opts);
        } catch (error) {
          this.setError(error);
          this.processError(error);
        }
      }
    },
    async fetchPurchaseOrder(uuid, status, ignoreZeroValidatedQuantities = false) {
      try {
        await this.fetchOnePurchaseOrderByStatus({ uuid, status, ignoreZeroValidatedQuantities });
      } catch (error) {
        this.setError(error);
        this.processError(error);
      }
    },
    async getLocationsHistory(locations, opts) {
      try {
        await this.getLocationsHistory({ locations, opts });
      } catch (error) {
        this.setError(error);
        this.processError(error);
      }
    },
    async getLocationHistory(location, opts) {
      try {
        await this.getPurchaseHistory({ locations: [location], opts });
      } catch (error) {
        this.setError(error);
        this.processError(error);
      }
    },
    async fetchTemperatures(opts) {
      try {
        await this.fetchAllTemperatures(opts);
      } catch (error) {
        this.setError(error);
        this.processError(error);
      }
    },
    async fetchEvents(opts) {
      try {
        return await this.fetchAllEvents(opts);
      } catch (error) {
        this.setError(error);
        this.processError(error);
      }
    },
    async getOneEvent(opts) {
      return await this.getEvent(opts);
    },
    async validateOneEvent(opts) {
      return await this.validateEvent(opts);
    },
    async createOneEvent(opts) {
      return await this.createEvent(opts);
    },
    async editOneEvent(opts) {
      return await this.editEvent(opts);
    },
    async skipEventDay(opts) {
      return await this.skipDay(opts);
    },
    async deleteOneEvent(opts) {
      return await this.deleteEvent(opts);
    },
  },
};
