import { orderBy } from 'lodash';

const ORGANIZATION = process.env.VUE_APP_IAM_ORGANIZATION;

export const authHelpers = {
  computed: {
    userLocations() {
      let groups = this.$auth.groups;
      return this.getRigths(groups, 'locations');
    },
    userLocationsWithLevel() {
      let groups = this.$auth.groups;
      return this.getRigthsWithLevel(groups, 'locations')
    },
    authorizedLocationsWithLevel() {
      let data= []
      let filtered = this.locations.filter(
        (location) =>
          location.parent_uuid &&
          this.userLocations[ORGANIZATION] &&
          this.userLocations[ORGANIZATION].includes(location.uuid),
      );
      filtered.forEach((loc) => {
        data.push({
          ...loc,
          levels: this.userLocationsWithLevel[ORGANIZATION][loc.uuid]
        })
      })
      return data
    },
    authorizedLocations() {
      return this.locations.filter(
        (location) =>
          location.parent_uuid &&
          this.userLocations[ORGANIZATION] &&
          this.userLocations[ORGANIZATION].includes(location.uuid),
      );
    },
    sortedAuthorizedLocations() {
      return orderBy(this.authorizedLocations, ['id'], ['asc']);
    },
  },
  methods: {
    getRigths(groups, right) {
      let reg = new RegExp(`/(.+?)/rights/${right}/(.*?)/(.*?)[^/]$`);
      let matched = groups.filter((group) => {
        return group.match(reg);
      });
      let res = {};
      matched.forEach((group) => {
        let splitted = group.split("/");
        let orga = splitted[1];
        let uuid = splitted[splitted.length - 1];
        if (!(orga in res)) {
          res[orga] = [];
        }
        res[orga].push(uuid);
      });
      return res;
    },
    getRigthsWithLevel(groups, right) {
      let reg = new RegExp(`/(.+?)/rights/${right}/(.*?)/(.*?)[^/]$`);
      let matched = groups.filter((group) => {
        return group.match(reg);
      });
      let res = {};
      matched.forEach((group) => {
        let splitted = group.split("/");
        let orga = splitted[1];
        let uuid = splitted[splitted.length - 1];
        let level = splitted[splitted.length - 2];
        if (!Object.keys(res).includes(orga)) {
          res[orga] = {};
        }
        if (!(Object.keys(res[orga]).includes(uuid))) {
          res[orga][uuid] = []
        }
        res[orga][uuid].push(level);
      });
      return res;
    },
  }
}
