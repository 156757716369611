import { formatPrice, formatCurrencyCode } from '.';

/**
 * An accessor object onto a Stock Manager event's product.
 */
export class Product {
  /**
   * @property {Object} _inner Inner event object representation
   * @private
   */
  _inner;

  /**
   * Default constructor.
   * @param {Object} data The data which whill be contained in the Product instance.
   */
  constructor(data) {
    this._inner = data;
  }

  /**
   * Product reference's UUID.
   * @returns {String}
   */
  get uuid() {
    return this._inner.uuid;
  }

  /**
   * Event's UUID bound to this entity.
   * @returns {String}
   */
  get event() {
    return this._inner.parent_uuid;
  }

  /**
   * Product's reference bound to the event.
   * @returns {String}
   */
  get ref() {
    return this._inner.object_uuid;
  }

  /**
   * Returns the object's type of the current product.
   * @returns {String}
   */
  get objectType() {
    return this._inner.object_type;
  }

  /**
   * Product's name if applicable.
   * @returns {String|null}
   */
  get objectName() {
    return this._inner.object_name || null;
  }

  /**
   * Product's completion status if applicable.
   * @returns {String|undefined}
   */
  get completionStatus() {
    return this._inner.completion_status;
  }

  /**
   * Product's delivered quantity if applicable.
   * @returns {Number|undefined}
   */
  get deliveredQuantity() {
    return this._inner.delivered_quantity;
  }

  /**
   * Product's delivered temperature if applicable.
   * @returns {Number|undefined}
   */
  get deliveredTemperature() {
    return this._inner.delivered_temperature;
  }

  /**
   * Product's associated quantity within the event.
   * @returns {Number}
   */
  get quantity() {
    return this._inner.quantity.toFixed(2);
  }

  /**
   * Product's associated unit.
   * @returns {String}
   */
  get unit() {
    return this._inner.unit;
  }

  /**
   * Product's price obtained with its quantity and cost per unit.
   * @return {String}
   */
  get price() {
    return formatPrice(this._inner.quantity * this._inner.cost_per_unit, this.currencyCode);
  }

  /**
   * Product's cost per unit.
   * @returns {String}
   */
  get costPerUnit() {
    return formatPrice(this._inner.cost_per_unit, this.currencyCode);
  }

  /**
   * Product's currency code.
   * @returns {String}
   */
  get currencyCode() {
    return this._inner.currency;
  }

  /**
   * Formatted product's currency code.
   * @returns {String}
   */
  get currency() {
    return formatCurrencyCode(this.currencyCode);
  }

  /**
   * Product's timestamps.
   * @returns {Object}
   */
  get timestamps() {
    const createdAt = typeof this._inner.created_at === 'string'
      ? new Date(this._inner.created_at)
      : this._inner.created_at;
    const updatedAt = typeof this._inner.updated_at === 'string'
      ? new Date(this._inner.updated_at)
      : this._inner.updated_at;

    return { createdAt, updatedAt };
  }
}
