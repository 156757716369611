import productsApi from '@/apis/purchaseOrders/products';
import productsTypes from './productsTypes';
import { keyBy } from 'lodash';

export default {
  namespaced: true,
  state: {
    products: [],
    categories: [],
    hubs: [],
    suppliers: [],
    fetchAllProducts: {
      pending: false,
    },
    fetchAllSuppliers: {
      pending: false,
    },
    fetchAllHubs: {
      pending: false,
    },
    fetchAllCategories: {
      pending: false,
    },
  },
  mutations: {
    [productsTypes.FETCH_ALL_PRODUCTS](state, products) {
      state.products = products;
    },
    [productsTypes.FETCH_ALL_PRODUCTS_PENDING](state, pending) {
      state.fetchAllProducts.pending = pending;
    },
    [productsTypes.FETCH_ALL_SUPPLIERS](state, suppliers) {
      state.suppliers = suppliers;
    },
    [productsTypes.FETCH_ALL_SUPPLIERS_PENDING](state, pending) {
      state.fetchAllSuppliers.pending = pending;
    },
    [productsTypes.FETCH_ALL_HUBS](state, hubs) {
      state.hubs = hubs;
    },
    [productsTypes.FETCH_ALL_HUBS_PENDING](state, pending) {
      state.fetchAllHubs.pending = pending;
    },
    [productsTypes.FETCH_ALL_CATEGORIES](state, categories) {
      state.categories = categories;
    },
    [productsTypes.FETCH_ALL_CATEGORIES_PENDING](state, pending) {
      state.fetchAllCategories.pending = pending;
    },
  },
  actions: {
    async fetchAllProducts({ commit }, opts) {
      try {
        commit(productsTypes.FETCH_ALL_PRODUCTS_PENDING, true);
        let products = await productsApi.fetchAllProducts(opts);
        commit(productsTypes.FETCH_ALL_PRODUCTS, products);
        return products;
      } finally {
        commit(productsTypes.FETCH_ALL_PRODUCTS_PENDING, false);
      }
    },
    async fetchAllHubs({ commit }, opts) {
      try {
        commit(productsTypes.FETCH_ALL_HUBS_PENDING, true);
        let hubs = await productsApi.fetchAllHubs(opts);
        commit(productsTypes.FETCH_ALL_HUBS, hubs);
        return hubs;
      } finally {
        commit(productsTypes.FETCH_ALL_HUBS_PENDING, false);
      }
    },
    async fetchAllSuppliers({ commit }, opts) {
      try {
        commit(productsTypes.FETCH_ALL_SUPPLIERS_PENDING, true);
        let suppliers = await productsApi.fetchAllSuppliers(opts);
        commit(productsTypes.FETCH_ALL_SUPPLIERS, suppliers);
        return suppliers;
      } finally {
        commit(productsTypes.FETCH_ALL_SUPPLIERS_PENDING, false);
      }
    },
    async fetchAllCategories({ commit }, opts) {
      try {
        commit(productsTypes.FETCH_ALL_CATEGORIES_PENDING, true);
        let categories = await productsApi.fetchAllCategories(opts);
        commit(productsTypes.FETCH_ALL_CATEGORIES, categories);
        return categories;
      } finally {
        commit(productsTypes.FETCH_ALL_CATEGORIES_PENDING, false);
      }
    },
    async fetchOneDeliver(_, { uuid, type, locationUuid }) {
      switch (type) {
        case 'SUPPLIER':
          return await productsApi.fetchOneSupplier(uuid, { locationUuid });
        case 'HUB':
          return await productsApi.fetchOneHub(uuid, { locationUuid });
        default:
          throw new Error('Not a valid type for deliver');
      }
    },
  },
  getters: {
    getDeliverByUuidAndType: (state) => (uuid, type) => {
      if (type === 'SUPPLIER') {
        return state.suppliers.find((_) => _.uuid === uuid);
      } else {
        return state.hubs.find((_) => _.uuid === uuid);
      }
    },
    getProductByUuid: (state) => (uuid) => {
      return state.products.find((_) => _.uuid === uuid);
    },
    productsKeyByUuid: (state) => {
      return keyBy(state.products, 'uuid');
    },
    getCategoryByUuid: (state) => (uuid) => {
      return state.categories.find((_) => _.uuid === uuid);
    },
  },
};
