export const priceFormatter = {
  data: () => ({
    currencies: {
      GBP: '£',
      EUR: '€',
      UAE: 'UAE',
      USD: '$',
    },
  }),
  methods: {
    getCurrency(currency) {
      return this.currencies[currency];
    },
    formatPrice(price, currency) {
      if (currency === 'UNKNOWN' || currency === 'EMPTY' || currency == null) {
        return this.$t('unknown');
      }

      const formatter = new Intl.NumberFormat(this.$i18n.locale, { style: 'currency', currency });
      const parsed = Number.parseFloat(price);

      if (Number.isNaN(parsed)) {
        return formatter
          .formatToParts()
          .map((part) => (part.type === 'nan' ? '-' : part.value))
          .join(' ');
      }

      return formatter.format(parsed);
    },
  },
};
