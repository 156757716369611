import inventoriesApi from '@/apis/purchaseOrders/inventories';
import { inventoriesTypes } from './purchaseOrdersTypes';
import gatewaySupplyApi from '@/apis/gatewaySupply';

export default {
  state: {
    beforeShift: {
      LUNCH: 'LUNCH',
      DINNER: 'DINNER',
    },
    inventories: {
      total: 0,
      items: [],
    },
    currentInventory: {},
    fetchAllInventories: {
      pending: false,
    },
    fetchOneInventory: {
      pending: false,
    },
    generateInventoryProducts: {
      pending: false,
    },
  },
  mutations: {
    [inventoriesTypes.FETCH_ALL_INVENTORIES](state, inventories) {
      state.inventories.total = inventories.total;
      state.inventories.items = inventories.items;
    },
    [inventoriesTypes.FETCH_ALL_INVENTORIES_PENDING]: (state, pending) => (state.fetchAllInventories.pending = pending),
    [inventoriesTypes.FETCH_ONE_INVENTORY]: (state, inventory) => (state.currentInventory = inventory),
    [inventoriesTypes.FETCH_ONE_INVENTORY_PENDING]: (state, pending) => (state.fetchOneInventory.pending = pending),
    [inventoriesTypes.UNSET_CURRENT_INVENTORY]: (state) => (state.currentInventory = {}),
    [inventoriesTypes.GENERATE_INVENTORY_PRODUCTS_PENDING]: (state, pending) =>
      (state.generateInventoryProducts.pending = pending),
  },
  actions: {
    addProductToInventory(_, { locationUuid, payload, refreshProductNeeds }) {
      return gatewaySupplyApi.addProductToCurrentInventory(locationUuid, payload, refreshProductNeeds);
    },
    async fetchAllInventories({ commit }, opts) {
      try {
        commit(inventoriesTypes.FETCH_ALL_INVENTORIES_PENDING, true);
        let inventories = await inventoriesApi.fetchAll(opts);
        commit(inventoriesTypes.FETCH_ALL_INVENTORIES, inventories);
        return inventories;
      } finally {
        commit(inventoriesTypes.FETCH_ALL_INVENTORIES_PENDING, false);
      }
    },
    async fetchOneInventory({ commit }, { uuid }) {
      try {
        commit(inventoriesTypes.FETCH_ONE_INVENTORY_PENDING, true);
        let inventory = await inventoriesApi.fetchOne(uuid);
        commit(inventoriesTypes.FETCH_ONE_INVENTORY, inventory);
        return inventory;
      } finally {
        commit(inventoriesTypes.FETCH_ONE_INVENTORY_PENDING, false);
      }
    },
    unsetCurrentInventory({ commit }) {
      commit(inventoriesTypes.UNSET_CURRENT_INVENTORY);
    },
    async updateProductQuantity(_, { inventoryUuid, inventoryProductUuid, quantity }) {
      return await inventoriesApi.updateProductQuantity(inventoryUuid, inventoryProductUuid, quantity);
    },
    async createOneInventory(_, payload) {
      return await inventoriesApi.createOne(payload);
    },
    async generateInventoryProducts({ commit }, { uuid }) {
      try {
        commit(inventoriesTypes.GENERATE_INVENTORY_PRODUCTS_PENDING, true);
        return await inventoriesApi.generateInventoryProducts(uuid);
      } finally {
        commit(inventoriesTypes.GENERATE_INVENTORY_PRODUCTS_PENDING, false);
      }
    },
    async createInventoryProduct(_, { uuid, product }) {
      return await inventoriesApi.createInventoryProduct(uuid, product);
    },
    async deleteOneInventory(_, uuid) {
      return await inventoriesApi.deleteOne(uuid);
    },
    async updateProductsQuantities(_, { uuid, payload }) {
      return await inventoriesApi.updateProductsQuantities(uuid, payload);
    },
    async updateOneInventory(_, { uuid, payload }) {
      return await inventoriesApi.updateOne(uuid, payload);
    },
  },
  getters: {},
};
