import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const inventoriesApi = new PurchaseOrdersClient.InventoriesApi();
inventoriesApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  fetchAll(opts) {
    return clientHook(inventoriesApi).appApisV1InventoriesListInventories(opts);
  },
  fetchOne(uuid) {
    return clientHook(inventoriesApi).appApisV1InventoriesGetInventory(uuid);
  },
  createOne(inventoryCreationRequest) {
    return clientHook(inventoriesApi).appApisV1InventoriesCreateInventory(inventoryCreationRequest);
  },
  updateOne(uuid, inventoryUpdateRequest) {
    return clientHook(inventoriesApi).appApisV1InventoriesUpdateInventory(uuid, inventoryUpdateRequest);
  },
  deleteOne(uuid) {
    return clientHook(inventoriesApi).appApisV1InventoriesDeleteInventory(uuid);
  },
  generateInventoryProducts(uuid) {
    return clientHook(inventoriesApi).appApisV1InventoriesGenerateInventoryProducts(uuid);
  },
  createInventoryProduct(uuid, inventoryProductCreationRequest) {
    return clientHook(inventoriesApi).appApisV1InventoriesCreateInventoryProduct(uuid, inventoryProductCreationRequest);
  },
  updateProductQuantity(inventoryUuid, inventoryProductUuid, quantity) {
    return clientHook(inventoriesApi).appApisV1InventoriesUpdateInventoryProductQuantity(inventoryUuid, inventoryProductUuid, quantity);
  },
  updateProductsQuantities(inventoryUuid, payload) {
    return clientHook(inventoriesApi).appApisV1InventoriesUpdateInventoryProductsQuantities(inventoryUuid, payload);
  }
};
