import { StockTransferEventApi } from '@tasterkitchens/stock-manager-client';
import Vue from 'vue';

const api = new StockTransferEventApi({
  basePath: process.env.VUE_APP_STOCK_MANAGER_ENDPOINT,
  accessToken: () => Vue.prototype.$auth.getToken(),
});

export default {
  /**
   * Returns an iterator over a Stock Transfer events collection.
   * @async
   * @param {Object} params API request parameters
   * @param {String|undefined} params.reason A unique identifier for a reason.
   * @param {Date|String|undefined} params.deliveryDate A specific delivery date.
   * @param {String|undefined} params.deliveryStatus A specific delivery status.
   * @param {String|undefined} params.destinationUuid A specific destination UUID.
   * @param {String|undefined} params.locationUuid A specific location UUID.
   * @param {String|undefined} params.orderBy A field we want to use to order the collection.
   * @param {Boolean} params.desc Does the collection needs to be reversed?
   * @param {String|undefined} params.startAfter The iterator should start after the provided UUID.
   * @param {Number} params.perPage The number of event per page.
   * @param {Boolean|undefined} params.withDeleted Should we retrieve soft deleted event too?
   */
  fetchAll({
    reason = undefined,
    deliveryDate = undefined,
    deliveryStatus = undefined,
    kitchenUuid = undefined,
    destinationUuid = undefined,
    locationUuid = undefined,
    orderBy = undefined,
    desc = false,
    startAfter = undefined,
    perPage = 20,
    withDeleted = undefined,
  } = {}) {
    if (deliveryDate) {
      deliveryDate = Vue.prototype.$dayjs(deliveryDate).format('YYYY-MM-DD');
    }

    return api.listTransfer(
      reason,
      deliveryDate,
      deliveryStatus,
      kitchenUuid,
      destinationUuid,
      locationUuid,
      orderBy,
      desc,
      startAfter,
      perPage,
      withDeleted,
    );
  },
  /**
   * Retrieves a Stock Transfer event from the manager.
   * @async
   * @param {String} uuid The UUID of the event to retrieve.
   */
  get(uuid) {
    return api.getTransfer(uuid)
  },
  /**
   * Creates a new Stock Transfer event in the manager.
   * @async
   * @param {*} payload The payload representing the new Stock Transfer event.
   */
  create(payload) {
    return api.createTransfer(payload);
  },
  /**
   * Updates an existing Stock Transfer event in the manager.
   * @async
   * @param {String} uuid The UUID of the event to update.
   * @param {*} payload The payload of the update.
   */
  update(uuid, payload) {
    return api.updateTransfer(uuid, payload);
  },
  /**
   * Updates products associated to an existing Stock Transfer event in the manager.
   * @async
   * @param {String} uuid The UUID of the event to update.
   * @param {*} products The new products collection bound to the event.
   */
  updateProducts(uuid, products) {
    return api.updateTransferProducts(uuid, { products });
  },
  /**
   * Update a product associated to an existing Stock Transfer event in the manager.
   * @async
   * @param {String} uuid The UUID of the event to update.
   * @param {String} productUuid The Product's UUID to update.
   * @param {*} payload The payload fo the update.
   */
  updateProduct(uuid, productUuid, payload) {
    return api.updateTransferProduct(uuid, productUuid, payload);
  },
  /**
   * Uploads a delivery note on the manager and associates with an existing Stock Transfer event.
   * @async
   * @param {String} uuid The UUID of the event which will receives the note.
   * @param {String} kitchenId The human-readable ID of the kitchen.
   * @param {String} deliverLabel The label on the delivery note.
   * @param {Blob} note The blob representing the note to be upload.
   */
  uploadNote(uuid, kitchenId, deliverLabel, note) {
    return api.uploadTransferNote(
      uuid,
      note,
      deliverLabel,
      kitchenId,
    );
  },
  /**
   * Pass a Transfer event from `AWAITING_DELIVERY` to `DELIVERED` state.
   * @async
   * @param {String} uuid The UUID of the event which will have its state changed.
   */
  passToDelivered(uuid) {
    return api.passTransferToDelivered(uuid);
  },
  /**
   * Soft deletes a Stock Transfer event in the manager.
   * @async
   * @param {String} uuid The UUID of the event to delete.
   */
  delete(uuid) {
    return api.deleteTransfer(uuid);
  },
};
