import events from './events';
import objects from './objects';

export default {
  namespaced: true,
  state: {
    ...events.state,
    ...objects.state,
  },
  mutations: {
    ...events.mutations,
    ...objects.mutations,
  },
  actions: {
    ...events.actions,
    ...objects.actions,
  },
  getters: {
    ...events.getters,
    ...objects.getters,
  },
};
