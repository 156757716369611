import clientHook from '@/apis';

const PurchaseOrdersClient = require('@tasterkitchens/purchase-orders-client');

const temperatureControlsApi = new PurchaseOrdersClient.TemperatureControlsApi();
temperatureControlsApi.apiClient.basePath = process.env.VUE_APP_PURCHASE_ORDERS_ENDPOINT;

export default {
  buildOne() {
    return {
      ...new PurchaseOrdersClient.TemperatureControlCreationRequest(),
      max_temp: undefined,
      min_temp: undefined,
    };
  },
  fetchAll(opts) {
    return clientHook(temperatureControlsApi).appApisV1TemperatureControlsListTemperatureControls(opts);
  },
  createOne(temperatureControl) {
    return clientHook(temperatureControlsApi).appApisV1TemperatureControlsCreateTemperatureControl(temperatureControl);
  },
  removeOne(temperatureControlUuid) {
    return clientHook(temperatureControlsApi).appApisV1TemperatureControlsDeleteTemperatureControlById(temperatureControlUuid);
  },
};
