import { mapState, mapActions, mapGetters } from "vuex";

export const restaurantsMgr = {
    computed: {
        ...mapState({
            currentLocation: (state) => state.restaurants.currentLocation,
            locations: (state) => state.restaurants.locations,
            fetchLocationsLoading: state => state.restaurants.fetchAllLocations.pending,
            fetchNestedLocationsLoading: state => state.restaurants.fetchAllNestedLocations.pending,
            currentKitchen: state => state.restaurants.currentKitchen,
        }),
        ...mapGetters('restaurants', ['getLocationByUuid']),
    },
    methods: {
        ...mapActions('restaurants', {
            fetchAllLocations: 'fetchAllLocations',
            setCurrentLocation: "setCurrentLocation",
            fetchOneKitchen: 'fetchOneKitchen',
            getCurrentShift: 'getCurrentShift',
        }),
        ...mapActions(["setError", "processError"]),
        async fetchLocations(force) {
            if ((!this.fetchLocationsLoading && this.locations.length === 0) || force) {
                try {
                    await this.fetchAllLocations();
                } catch (error) {
                    this.setError(error)
                    this.processError(error)
                }
            }
        },
        async fetchCurrentKitchen() {
            if (this.currentLocation && (!this.currentKitchen || this.currentLocation.kitchens[0] !== this.currentKitchen.uuid) ){
                try {
                    await this.fetchOneKitchen({uuid: this.currentLocation.kitchens[0]});
                } catch (error) {
                    this.setError(error)
                    this.processError(error)
                }
            }
        },
    }
}
