<template>
  <b-menu-item
    :active="active"
    :icon="icon"
    tag="router-link"
    :to="to"
  >
    <template #label>
      <span>
        {{ label }}
        <slot name="decorator" />
      </span>
    </template>
  </b-menu-item>
</template>

<script>
export default {
  name: 'SideBarItem',
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasChildren() {
      return this.route.children && Array.isArray(this.route.children);
    },
    active() {
      if (this.hasChildren) {
        return this.route.children.reduce(
          (active, route) => active || (this.$route.name === route.name),
          false,
        );
      }

      return this.$route.name === this.route.name;
    },
    icon() {
      return this.route.icon || null;
    },
    label() {
      return this.$t(`menu.${this.route.keyName}`);
    },
    to() {
      return {
        name: this.hasChildren ? this.route.children[0].name : this.route.name,
      };
    },
  },
};
</script>
