import { Product } from './product';
import { formatPrice, formatCurrencyCode } from '.';

/**
 * @enum An enumeration of possible Stock manager events.
 */
export const EventType = {
  StockIn: 'STOCK_IN',
  StockOut: 'STOCK_OUT',
  Transfer: 'TRANSFER',
};

/**
 * An accessor object onto a Stock Manager event.
 */
export class Event {
  /**
   * @private
   * @property {EventType} _type The event type of the current representation.
   */
  _type;

  /**
   * @private
   * @property {Object} _inner Inner event object representation
   */
  _inner;

  /**
   * Default constructor.
   * @param {String} type The underlying type of the event
   * @param {Object} data The data which whill be contained in the Event instance.
   */
  constructor(type, data) {
    this._type = type;
    this._inner = data;
  }

  /**
   * Event's UUID.
   * @returns {String}
   */
  get uuid() {
    return this._inner.uuid;
  }

  /**
   * Event's location UUID.
   * @returns {String}
   */
  get location() {
    return this._inner.location_uuid;
  }

  /**
   * Event's location label.
   * @returns {String}
   */
  get locationLabel() {
    return this._inner.location_label;
  }

  /**
   * Event's destination UUID if it's a Transfer event.
   * @returns {String|undefined}
   */
  get destination() {
    if (this._type !== EventType.Transfer) {
      return undefined;
    }

    return this._inner.destination_uuid;
  }

  /**
   * Event's destination label if it's a Transfer event.
   * @return {String|undefined}
   */
  get destinationLabel() {
    if (this._type !== EventType.Transfer) {
      return undefined;
    }

    return this._inner.destination_label;
  }

  /**
   * Event's date.
   * @returns {Date}
   */
  get date() {
    if (typeof this._inner.date === 'string') {
      this._inner.date = new Date(this._inner.date);
    }

    return this._inner.date;
  }

  /**
   * Event's delivery date if it's a Transfer event.
   * @return {Date|undefined}
   */
  get deliveryDate() {
    if (this._type !== EventType.Transfer) {
      return undefined;
    }
    
    if (typeof this._inner.delivery_date === 'string') {
      this._inner.delivery_date = new Date(this._inner.delivery_date);
    }

    return this._inner.delivery_date;
  }

  /**
   * Event's delivery progress if it's a Transfer event.
   * @returns {Number|undefined}
   */
  get deliveryProgress() {
    if (this._type !== EventType.Transfer) {
      return undefined;
    }

    return this._inner.delivery_progress;
  }

  /**
   * Event's delivery status if it's a Transfer event.
   * @returns {String|undefined}
   */
  get deliveryStatus() {
    if (this._type !== EventType.Transfer) {
      return undefined;
    }

    return this._inner.delivery_status;
  }

  /**
   * Event's fungible value.
   * @returns {Number}
   */
  get totalPrice() {
    return formatPrice(this._inner.total_price, this.totalPriceCurrencyCode);
  }

  /**
   * Event's fungible value currency code.
   * @returns {String}
   */
  get totalPriceCurrencyCode() {
    return this._inner.total_currency;
  }

  /**
   * Formatted event's fungible value currency code.
   * @returns {String}
   */
  get totalPriceCurrency() {
    return formatCurrencyCode(this.totalPriceCurrencyCode);
  }

  /**
   * Event's logistic cost if applicable.
   * @returns {Number | null}
   */
  get logisticCost() {
    if (!this._inner.logistic_cost) {
      return null;
    }

    return formatPrice(this._inner.logistic_cost, this.logisticCostCurrencyCode);
  }

  /**
   * Event's logistic cost currency if applicable.
   * @returns {String | null}
   */
  get logisticCostCurrencyCode() {
    if (!this._inner.logistic_currency) {
      return null;
    }

    return this._inner.logistic_currency;
  }

  /**
   * Formatted event's logistic cost currency if applicable.
   * @returns {String | null}
   */
  get logisticCostCurrency() {
    if (!this.logisticCostCurrencyCode) {
      return null;
    }

    return formatCurrencyCode(this.logisticCostCurrencyCode);
  }

  /**
   * Event's reason.
   * @returns {String|null}
   */
  get reason() {
    return this._inner.reason;
  }

  /**
   * Event's comment.
   * @returns {String|null}
   */
  get comment() {
    return this._inner.comment;
  }

  /**
   * Event's notes if it's a Stock Transfer event.
   * @return {String[] | undefineed}
   */
  get notes() {
    if (!this._type !== EventType.Transfer) {
      return undefined;
    }

    return this._inner.notes;
  } 

  /**
   * Event's note if it's updated.
   * @returns {String | null}
   */
  get note() {
    return this._inner.note;
  }

  /**
   * Event's creator UUID.
   * @returns {String}
   */
  get createdBy() {
    return this._inner.created_by;
  }

  /**
   * Event's product list.
   * @returns {Product[]}
   */
  get products() {
    return this._inner.products.map(p => new Product(p));
  }

  /**
   * Event's timestamps.
   * @returns {Object}
   */
  get timestamps() {
    const createdAt = typeof this._inner.created_at === 'string'
      ? new Date(this._inner.created_at)
      : this._inner.created_at;
    const updatedAt = typeof this._inner.updated_at === 'string'
      ? new Date(this._inner.updated_at)
      : this._inner.updated_at;

    return { createdAt, updatedAt };
  }
}
