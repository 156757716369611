import Vue from 'vue';
import Vuex from 'vuex';

import stock from './modules/stock';
import purchaseOrders from './modules/purchaseOrders';
import restaurants from './modules/restaurants';
import products from './modules/products';
import gatewaySupplyV1 from './modules/gatewaySupplyV1';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    error: null,
  },
  mutations: {
    ['SET_ERROR']: (state, error) => {
      state.error = error;
    },
    ['UNSET_ERROR']: (state) => {
      state.error = null;
    },
  },
  actions: {
    setError({ commit }, error) {
      let message = 'Unknown error occured';
      if (error.body) {
        message = error.body.detail;
      }
      commit('SET_ERROR', message);
      throw error;
    },
    unsetError({ commit }) {
      commit('UNSET_ERROR');
    },
    processError(_, err) {
      if (err instanceof Error) {
        throw err;
      } else if (err.error && err.body) {
        const error = err.error.response.error;
        error.message = `${err.message} ${JSON.stringify(err.body.detail)}`;
        throw error;
      }

      throw new Error(JSON.stringify(err));
    },
  },
  modules: {
    purchaseOrders,
    restaurants,
    stock,
    products,
    gatewaySupplyV1,
  },
});

export default store;
