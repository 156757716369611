import { roles, groups } from './roles';

const Index = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Index.vue');
const Home = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Home.vue');
const ListPurchaseOrders = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/PurchaseOrders/ListPurchaseOrders.vue');
const ShowPurchaseOrder = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/PurchaseOrders/ShowPurchaseOrder.vue');
const Board = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Board/Board.vue');
const TemperatureControlsList = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/TemperatureControls/TemperatureControlsList.vue');
const ListInventories = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Inventories/ListInventories.vue');
const ShowInventory = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Inventories/ShowInventory.vue');
const Costs = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Costs/Costs.vue');
const Monitoring = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Monitoring/Monitoring.vue');
const Schedule = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Schedule.vue');
const Issues = () => import(/* webpackChunkName: "admin" */ '../views/Admin/Issues/IssuesList.vue');

export default {
  path: '/admin',
  keyName: 'admin',
  menuRedirect: 'adminHome',
  display: true,
  menu: true,
  icon: 'lock',
  component: Index,
  meta: {
    authorize: [
      roles.SupplyAdmin,
      roles.SupplyPurchaseHistoryViewer,
      roles.SupplyInventoryViewer,
      roles.SupplyInventoryEditor,
      roles.SupplyTemperatureEditor,
    ],
    deprecated: true,
  },
  children: [
    {
      path: '',
      name: 'adminHome',
      keyName: 'adminHome',
      display: false,
      menu: false,
      component: Home,
      meta: {
        authorize: [
          roles.SupplyAdmin,
          roles.SupplyPurchaseHistoryViewer,
          roles.SupplyInventoryViewer,
          roles.SupplyInventoryEditor,
          roles.SupplyTemperatureEditor,
        ],
        deprecated: true,
      },
    },
    {
      name: 'adminMonitoring',
      keyName: 'adminMonitoring',
      path: 'monitoring',
      icon: 'television-guide',
      display: true,
      component: Monitoring,
      meta: { authorize: [roles.SupplyAdmin], deprecated: true },
    },
    {
      name: 'adminPurchaseOrdersBoard',
      keyName: 'adminPurchaseOrdersBoard',
      path: 'purchase-orders/board',
      icon: 'view-dashboard',
      display: true,
      component: Board,
      meta: { authorize: [roles.SupplyAdmin], deprecated: true },
    },
    {
      name: 'eventList',
      keyName: 'schedule',
      path: 'schedule',
      icon: 'calendar',
      display: true,
      component: Schedule,
      meta: { authorize: [roles.SupplyAdmin], deprecated: true },
    },
    {
      name: 'adminListPurchaseOrders',
      keyName: 'adminListPurchaseOrders',
      path: 'purchase-orders',
      icon: 'truck',
      display: true,
      component: ListPurchaseOrders,
      meta: { authorize: [roles.SupplyAdmin], deprecated: true },
    },
    {
      name: 'adminShowPurchaseOrder',
      keyName: 'adminShowPurchaseOrder',
      path: 'purchase-orders/:status/:id',
      display: false,
      component: ShowPurchaseOrder,
      meta: { authorize: [roles.SupplyAdmin], deprecated: true },
    },
    {
      name: 'adminTemperatureControls',
      keyName: 'adminTemperatureControls',
      path: 'temperature-controls',
      icon: 'thermometer-lines',
      display: true,
      component: TemperatureControlsList,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyTemperatureEditor], deprecated: true },
    },
    {
      name: 'adminListInventories',
      keyName: 'adminListInventories',
      path: 'inventories',
      icon: 'inbox',
      display: true,
      component: ListInventories,
      meta: {
        authorize: [roles.SupplyAdmin, roles.SupplyInventoryViewer, roles.SupplyInventoryEditor],
        deprecated: true,
      },
    },
    {
      name: 'adminShowInventory',
      keyName: 'adminShowInventory',
      path: 'inventories/:id',
      display: false,
      component: ShowInventory,
      meta: {
        authorize: [roles.SupplyAdmin, roles.SupplyInventoryViewer, roles.SupplyInventoryEditor],
        deprecated: true,
      },
    },
    {
      name: 'adminCosts',
      keyName: 'adminCosts',
      path: 'costs',
      icon: 'cash',
      display: true,
      component: Costs,
      meta: { authorize: [roles.SupplyAdmin, roles.SupplyPurchaseHistoryViewer], deprecated: true },
    },
    {
      name: 'adminIssues',
      keyName: 'adminIssues',
      path: 'issues',
      icon: 'alert',
      betaGroups: [groups.BetaIssues],
      display: true,
      component: Issues,
      meta: { authorize: [roles.SupplyAdmin], deprecated: true },
    },
  ],
};
