import { mapState, mapActions } from 'vuex';

export const errorHandler = {
  computed: {
    ...mapState({ error: (state) => state.error }),
  },
  methods: {
    ...mapActions(['setError', 'unsetError', 'processError']),
  },
  beforeMount() {
    this.unsetError();
  },
};
