import locationApi from '@/apis/stock/location';
import { ObjectType } from '@/utils/stock/object';
import { objectTypes } from './types';

/**
 * Find an object into the chunked collection by locations with a provided callback function.
 * @param {Object} chunks The chunked stock object collection.
 * @param {Function} cb The search function callback.
 * @returns {Object|undefined}
 */
function findObject(chunks, cb) {
  const objects = Object.values(chunks)
    .reduce((output, objects) => output.concat(objects), []);

  return objects.find(cb);
}

export default {
  state: {
    objectsByLocation: {
      items: {},
      menuItems: {},
      products: {},
      objects: {},
    },
    fetchingObjectsFor: [],
  },
  mutations: {
    [objectTypes.FETCH_ALL_OBJECTS](state, { locationUuid, kind, objects }) {
      state.objectsByLocation[kind][locationUuid] = objects;
    },
    [objectTypes.FETCH_ALL_OBJECTS_PENDING](state, { locationUuid, kind, pending }) {
      if (pending) {
        state.fetchingObjectsFor = [
          ...state.fetchingObjectsFor,
          `${locationUuid}-${kind}`,
        ];
      } else {
        state.fetchingObjectsFor = state.fetchingObjectsFor.filter(id => id !== `${locationUuid}-${kind}`);
      }
    },
  },
  actions: {
    async fetchAllItems({ state, commit }, { locationUuid, force = false }) {
      if (state.objectsByLocation.items[locationUuid] && !force) {
        return;
      }

      try {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'items', pending: true });
        const res = await locationApi.fetchStockItems(locationUuid);
        commit(objectTypes.FETCH_ALL_OBJECTS, { locationUuid, kind: 'items', objects: res.data });
      } finally {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'items', pending: false });
      }
    },
    async fetchAllMenuItems({ state, commit }, { locationUuid, force }) {
      if (state.objectsByLocation.menuItems[locationUuid] && !force) {
        return;
      }

      try {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'menuItems', pending: true });
        const res = await locationApi.fetchStockMenuItems(locationUuid);
        commit(objectTypes.FETCH_ALL_OBJECTS, { locationUuid, kind: 'menuItems', objects: res.data });
      } finally {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'menuItems', pending: false });
      }
    },
    async fetchAllObjects({ state, commit }, { locationUuid, force }) {
      if (state.objectsByLocation.objects[locationUuid] && !force) {
        return;
      }

      try {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'objects', pending: true });
        const res = await locationApi.fetchStockObjects(locationUuid);
        commit(objectTypes.FETCH_ALL_OBJECTS, { locationUuid, kind: 'objects', objects: res.data });
      } finally {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'objects', pending: false });
      }
    },
    async fetchAllProducts({ commit, state }, { locationUuid, force = false }) {
      if (state.objectsByLocation.products[locationUuid] && !force) {
        return;
      }

      try {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'products', pending: true });
        const res = await locationApi.fetchStockProducts(locationUuid);
        commit(objectTypes.FETCH_ALL_OBJECTS, { locationUuid, kind: 'products', objects: res.data });
      } finally {
        commit(objectTypes.FETCH_ALL_OBJECTS_PENDING, { locationUuid, kind: 'products', pending: false });
      }
    },
  },
  getters: {
    fetchingObjects: state => state.fetchingObjectsFor.length > 0,
    isFetchingItems: state => uuid => {
      return state.fetchingObjectsFor.indexOf(`${uuid}-items`) >= 0;
    },
    isFetchingMenuItems: state => uuid => {
      return state.fetchingObjectsFor.indexOf(`${uuid}-menuItems`) >= 0;
    },
    isFetchingObjects: state => uuid => {
      return state.fetchingObjectsFor.indexOf(`${uuid}-objects`) >= 0;
    },
    isFetchingProducts: state => uuid => {
      return state.fetchingObjectsFor.indexOf(`${uuid}-products`) >= 0;
    },
    getStockObjects: state => uuid => {
      return state.objectsByLocation.objects[uuid];
    },
    getStockProducts: state => uuid => {
      return state.objectsByLocation.products[uuid];
    },
    getItemByUuid: state => uuid => {
      const item = findObject(state.objectsByLocation.items, item => item.uuid === uuid);
      if (!item) {
        return findObject(state.objectsByLocation.objects, item => item.type === ObjectType.Item && item.uuid === uuid);
      }

      return item;
    },
    getMenuItemByUuid: state => uuid => {
      const menuItem = findObject(state.objectsByLocation.menuItems, menuItem => menuItem.uuid === uuid);
      if (!menuItem) {
        return findObject(state.objectsByLocation.objects, item => item.type === ObjectType.MenuItem && item.uuid === uuid);
      }

      return menuItem;
    },
    getProductByUuid: state => uuid => {
      const product = findObject(state.objectsByLocation.products, product => product.uuid === uuid);
      if (!product) {
        return findObject(state.objectsByLocation.objects, item => item.type === ObjectType.Product && item.uuid === uuid);
      }

      return product;
    },
  },
};
