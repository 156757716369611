<template>
  <div class="home" v-if="currentLocation && !error">
    <section class="hero is-taster-blue" v-if="hero">
      <div class="hero-body is-fixed">
        <h1 class="title">Administration</h1>
        <h2 class="subtitle">Purchase order management</h2>
      </div>
    </section>
    <section class="py-3 mb-4 is-absolute">
      <div class="columns is-multiline">
        <div class="column is-one-third" v-if="isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'adminMonitoring' })"
          >
            <div class="is-size-1">🖥</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('home.adminMonitoring') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.adminMonitoringMessage') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'adminPurchaseOrdersBoard' })"
          >
            <div class="is-size-1">📋</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">{{ $t('home.board') }}</div>
            <div class="has-text-grey is-size-7">{{ $t('home.boardMessage') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isSupplyAdmin">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'adminListPurchaseOrders' })"
          >
            <div class="is-size-1">🗃️</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">{{ $t('home.history') }}</div>
            <div class="has-text-grey is-size-7">{{ $t('home.historyMessage') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isSupplyAdmin || isSupplyTemperatureEditor">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'adminTemperatureControls' })"
          >
            <div class="is-size-1">🌡️</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('home.temperature') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.temperatureMessage') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isSupplyAdmin || isSupplyInventoryViewer || isSupplyInventoryEditor">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'adminListInventories' })"
          >
            <div class="is-size-1">📝</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('home.inventoryHistory') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.inventoryHistoryMessage') }}</div>
          </div>
        </div>
        <div class="column is-one-third" v-if="isSupplyAdmin || isSupplyPurchaseHistoryViewer">
          <div
            class="box box-tiled has-text-centered had-cursor-pointer mx-3"
            @click="$router.push({ name: 'adminCosts' })"
          >
            <div class="is-size-1">💶</div>
            <div class="is-size-5 is-uppercase has-text-weight-semibold has-text-black">
              {{ $t('home.adminCosts') }}
            </div>
            <div class="has-text-grey is-size-7">{{ $t('home.adminCostsMessage') }}</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { hasRole } from '@/router/roles';
import { restaurantsMgr, errorHandler } from '@/mixins';

export default {
  mixins: [restaurantsMgr, errorHandler],
  props: {
    hero: { type: Boolean, default: true },
  },
  computed: {
    isSupplyAdmin() {
      return hasRole('SupplyAdmin');
    },
    isSupplyPurchaseHistoryViewer() {
      return hasRole('SupplyPurchaseHistoryViewer');
    },
    isSupplyInventoryViewer() {
      return hasRole('SupplyInventoryViewer');
    },
    isSupplyInventoryEditor() {
      return hasRole('SupplyInventoryEditor');
    },
    isSupplyTemperatureEditor() {
      return hasRole('SupplyTemperatureEditor');
    },
  },
};
</script>

<style lang="scss" scoped>
.had-cursor-pointer {
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    transform: scale(1.02);
  }
}
.had-cursor-pointer:hover {
  box-shadow: 0 4px 6px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.box-tiled {
  border-radius: 0 !important;
  height: 100%;
}
</style>
