/**
 * Formats a price with its currency into the user locale language.
 * @param {Number} price The price to format.
 * @param {String} currency The currency code of the price.
 * @returns {String}
 */
export function formatPrice(price, currency) {
  const formatter = new Intl.NumberFormat(navigator.language || navigator.userLanguage || 'en-US', {
    style: 'currency',
    currency,
  });

  return formatter.format(price.toFixed(2));
}

/**
 * Formats the currency code into a localized string base on the user language.
 * @param {String} currency The currency code to format.
 * @returns {String}
 */
export function formatCurrencyCode(currency) {
  const formatter = new Intl.NumberFormat(navigator.language || navigator.userLanguage || 'en-US', {
    style: 'currency',
    currency,
  });

  return formatter.formatToParts(1)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .pop();
}
